<template>
    <div class="recommend-dynamic" v-loading="loading">
        <template v-if="listData && listData.length > 0">
            <div class="recommend-dynamic-item" v-for="(recommend, index) in listData" :key="index">
                <!-- 未处理或推荐待反馈状态 -->
                <template v-if="recommend.type == 1">
                    <div class="recommend-dynamic-text">
                        <span class="text-green">{{recommend.operator}}</span>
                        推荐候选人至【<span class="text-green">{{recommend.customer}} 
                            <!-- <a target="_blank" :href="'/Headhunting/MyCompany.html#/Job/' + recommend.jobId">{{recommend.job}}</a> -->
                            <a target="_blank" :href="'/#/Job/' + recommend.jobId">{{recommend.job}}</a>
                        </span>】
                    </div>
                    <div class="recommend-dynamic-time">{{recommend.operationDate}}</div>
                </template>
                <!-- 已接受待推进面试状态 -->
                <template v-if="recommend.type == 2">
                    <div class="recommend-dynamic-text">
                        <span class="text-green">{{recommend.operator}}</span>
                        接受了候选人
                    </div>
                    <div class="recommend-dynamic-time">{{recommend.operationDate}}</div>
                </template>
                <!-- 面试待确认状态 -->
                <template v-if="recommend.type == 28">
                    <div class="recommend-dynamic-text">
                        <span class="text-green">{{recommend.operator}}</span>
                        将候选人推进到面试阶段，等待
                        <span class="text-green">{{recommend.unconfirmedUserName}}</span>
                        确认
                    </div>
                    <div class="recommend-dynamic-time">{{recommend.operationDate}}</div>
                </template>
                <!-- 面试待安排状态 -->
                <template v-if="recommend.type == 5">
                    <div class="recommend-dynamic-text">
                        <span class="text-green">{{recommend.operator}}</span>
                        将候选人推进到面试阶段
                    </div>
                    <div class="recommend-dynamic-time">{{recommend.operationDate}}</div>
                </template>
                <!-- 推荐拒绝状态 -->
                <template v-if="recommend.type == 3">
                    <div class="recommend-dynamic-text">
                        <span class="text-green">{{recommend.operator}}</span>
                        拒绝了候选人
                    </div>
                    <div class="recommend-dynamic-time">{{recommend.operationDate}}</div>
                </template>
                <!-- 重新接受 -->
                <template v-if="recommend.type == 4">
                    <div class="recommend-dynamic-text">
                        <span class="text-green">{{recommend.operator}}</span>
                        重新接受了候选人
                    </div>
                    <div class="recommend-dynamic-time">{{recommend.operationDate}}</div>
                </template>
                <!-- 面试已安排状态 -->
                <template v-if="recommend.type == 6">
                    <div class="recommend-dynamic-text">
                        <span class="text-green">{{recommend.operator}}</span>
                        为候选人安排了面试
                    </div>
                    <div class="recommend-dynamic-time">{{recommend.operationDate}}</div>
                </template>
                <!-- 面试已反馈状态 -->
                <template v-if="recommend.type == 8">
                    <div class="recommend-dynamic-text">
                        <span class="text-green">{{recommend.operator}}</span>
                        反馈了候选人的面试结果（{{recommend.interviewResult}}）
                    </div>
                    <div class="recommend-dynamic-time">{{recommend.operationDate}}</div>
                </template>
                <!-- Offer状态 -->
                <template v-if="recommend.type == 9">
                    <div class="recommend-dynamic-text">
                        <span class="text-green">{{recommend.operator}}</span>
                        候选人推送到已Offer阶段
                    </div>
                    <div class="recommend-dynamic-time">{{recommend.operationDate}}</div>
                </template>
                <!-- 入职状态 -->
                <template v-if="recommend.type == 10">
                    <div class="recommend-dynamic-text">
                        <span class="text-green">{{recommend.operator}}</span>
                        将候选人推送到入职阶段
                    </div>
                    <div class="recommend-dynamic-time">{{recommend.operationDate}}</div>
                </template>
                <!-- 离职状态 -->
                <template v-if="recommend.type == 17">
                    <div class="recommend-dynamic-text">
                        <span class="text-green">{{recommend.operator}}</span>
                        将候选人设置为离职状态
                    </div>
                    <div class="recommend-dynamic-time">{{recommend.operationDate}}</div>
                </template>
                <!-- 淘汰状态 -->
                <template v-if="recommend.type == 11">
                    <div class="recommend-dynamic-text">
                        <span class="text-green">{{recommend.operator}}</span>
                        淘汰了候选人
                    </div>
                    <div class="recommend-dynamic-time">{{recommend.operationDate}}</div>
                </template>
                <!-- 淘汰后被恢复状态 -->
                <template v-if="recommend.type == 26">
                    <div class="recommend-dynamic-text">
                        <span class="text-green">{{recommend.operator}}</span>
                        恢复了候选人
                    </div>
                    <div class="recommend-dynamic-time">{{recommend.operationDate}}</div>
                </template>

                <!-- offer中的子状态 -->
                <!-- <template v-if="[28, 29, 30, 31, 32, 33].includes(recommend.type)"> -->
                <template v-if="[41, 29, 30, 31, 32, 33].includes(recommend.type)">
                    <div class="recommend-dynamic-text">
                        <span class="text-green">{{recommend.operator}}</span>
                        候选人进入{{offerStatusMap[recommend.type]}}阶段
                    </div>
                    <div class="recommend-dynamic-time">{{recommend.operationDate}}</div>
                </template>

                <template v-if="[35, 37].includes(recommend.type)">
                    <div class="recommend-dynamic-text">
                        <span class="text-green">{{recommend.operator}}</span>
                        <span v-html="genereteHtml(recommend)"></span>
                        <!-- 候选人进入{{offerStatusMap[recommend.type]}}阶段 -->
                    </div>
                    <div class="recommend-dynamic-time">{{recommend.operationDate}}</div>
                </template>

                <!-- 操作按钮 仅第一条动态展示 -->
                <recommend-operation 
                    :recommend="recommendation" 
                    :isRecommendList="true"
                    @show-performance-dialog="showPerformanceDialog"
                    @show-dialog="showDialog" 
                    v-if="recommendation.canOperate && index == 0"
                    :recommend-position="recommendPosition"
                ></recommend-operation>
            </div>
        </template>
        <!-- <div class="recommend-dynamic-item" v-if="listData.length == 0">
            {{tipShow}}
        </div> -->
        <div class="recommend-dynamic-item" v-if="!listData">正在加载</div>
        <div class="recommend-dynamic-item" v-if="listData && listData.length == 0">暂无数据</div>
    </div>
</template>

<script>
import recommendOperation from '#/component/workTable/recommend-operation.vue';


export default {
    props: {
        listData: Array,
        recommendation: Object, //候选人信息
        index: 0,
        recommendPosition: '', //点击按钮的位置，表格行or展开行
    },
    components: {
        recommendOperation
    },
    data() {
        return {
            loading: false,
            tipShow: '正在加载',
            offerStatusMap: {
                // 28: '待确认面试', 
                41: 'S1全部面试通过', 
                29: 'S2提交流水等材料', 
                30: 'S3客户审批offer', 
                31: 'S4沟通offer细节', 
                32: 'S5等待接受offer', 
                33: 'S6双方确认offer'
            }
        }
    },
    mounted() {
        // this.getRecommendations();
    },
    methods: {
        getRecommendations() {
            let recommendationId = this.recommendation.id;
            this.laoding = true;
            _request({
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                method: 'GET',
                url: "/Headhunting/FloatingList/Recommendation/%p/Flows".replace(/\%p/ig,recommendationId)
            }).then((res) => {
                this.laoding = false;
                if(res){
                    this.listData = res;
                    if(res.length == 0) {
                        this.tipShow = '暂无数据';
                    }
                }
            }).catch((err) => {
                this.laoding = false;
                console.log(err);
            });
        },
        showDialog( index ,nextStatus, recommend, btnText) {
            this.$emit('show-dialog', this.$props.index, nextStatus, recommend, btnText);
        },
        showPerformanceDialog(index, recommend) {
            this.$emit('show-performance-dialog', index, recommend);
        },
        genereteHtml(item) {
            const actionsMap = ['创建', '重新申请', '编辑', '填写', '填写', '驳回', '取消', '移交', '确认', '作废'];
            const paymentMethodMap = ['全款', '首款', '尾款'];
            const applicationTypeMap = ['', '负数'];
            const ticketsType = ['开票申请', '开票申请', '开票申请', '开票信息', '回款信息', '开票申请', '开票申请', '开票申请', '开票申请', '开票申请'];

            return ` <span>${actionsMap[+item.extensionProperties.operationType]}了${paymentMethodMap[+item.extensionProperties.paymentMethodType]}${applicationTypeMap[+item.extensionProperties.invoiceApplicationType]}${ticketsType[+item.extensionProperties.operationType]}</span>
            ,当前状态为${item.type == 35 ? (item.extensionProperties.recommendStatus == '26' ? '已回款': '开票中'): '离职'}
            `;
            
        },
    }
}
</script>

<style lang="scss" scoped>
    .recommend-dynamic {
        width: 100%;
        padding: 16px 0 6px 0;
        padding-left: 162px;
        background-color: #eee;
        font-size: 14px;
        line-height: 19px;
        color: #666;
        &-item {
            margin-bottom: 10px;
        }
        &-item > div {
            display: inline-block;

        }
        &-text {
            margin-right: 10px;
        }
        &-time {
            margin-right: 30px;
            font-size: 12px;
            color: #999;
        }
        .text-green {
            color: $primary;
        }
        .text-green > a {
            color: $primary;
        }
    }
</style>
