<template>
<div class="work-table-container">
    <div class="work-table">
        <div class="filter-wrap">
            <floatingListBubble
                id="bubble"
                ref="bubble"
                :bubbleType="filterIndex"
                :bubbleCount="statusBubbleCount"
                @bubble-click="bubbleClick"
                @bubble-clear="bubbleClear"
                @timeLimit-change="timeLimitChange"
            ></floatingListBubble>

            <div class="filter-area">
                <div class="filter-left-text">筛选条件：</div>
                <div 
                    class="filter-cell"
                    :class="{'hot-light': aCurrentLabel.includes(item)}"
                    v-for="(item, index) in filterStore"
                    :key="'floatingFilter_' + index"
                    v-show="headerFilter[tableTitleMap[item].name].length>0"
                    >
                    <select-popover
                        :maxSelect="30"
                        :enableCount="true"
                        :title="tableTitleMap[item].label"
                        :label="tableTitleMap[item].name"
                        :enableSearch="tableTitleMap[item].headerSelectEnableSearch"
                        :selectItem="headerFilter[tableTitleMap[item].name]"
                        :defaultSelectedItems="tableTitleMap[item].hasDefaultSelectedItems ? defaultSelect[tableTitleMap[item].name] : []"
                        @selectChange="selectDataChange"
                    ></select-popover>
                </div>
            </div>
        </div>

        <!-- <div class="select-container"> -->
        <!-- 再次操作确认进入面试列表相关代码 -->
        <div class="select-container" :style="isCFUser && isShowConfirmListBtn ? 'padding-right: 182px' : ''">
        <!-- 再次操作确认进入面试列表相关代码 end -->
            <span>共
                <span class="color-orange">{{ candidateTotal }}</span>
                位候选人，
                <span class="color-orange">{{page.total}}</span>
                条记录
            </span>
            <!-- <span class="select-container-point-tip" v-if="isCFUser">及时变更推荐状态每次可得5积分哦</span> -->
            <div class="select-tag-list">
                <div class="select-tag" 
                    v-for="(item, tag) in selectData" 
                    :key="tag" 
                    v-show="item.length > 0">
                    <el-tooltip
                        placement="top-start"
                        effect="light"
                        popper-class="label-tip">
                        <div slot="content">{{ item | labelsTip }}</div>
                        <div class="text-ellipsis">
                            <span>{{ selectLabelMap[tag] }} :</span>
                            <span class="content" v-for="(param, index) in item" :key="index">
                                {{ param.label }}
                                <span v-if="item.length > 0 && index !== (item.length - 1)">,</span>
                            </span>
                        </div>
                    </el-tooltip>
                    <span class="btn-close" @click="clearSelect(tag)"></span>
                </div>
            </div>
            <!-- 再次操作确认进入面试列表相关代码 -->
            <div
                class="show-confirm-list"
                v-if="isCFUser && isShowConfirmListBtn">
                <el-button
                    class="show-confirm-list-btn"
                    :style="showConfirmListBtnGuide ? 'pointer-events: none;' : ''"
                    @click="showConfListDialog">
                    确认进入面试列表
                </el-button>
                <div class="show-confirm-list-guide" v-if="showConfirmListBtnGuide">
                    点击此处进入确认进入面试列表
                    <span class="hide-guide-btn el-icon-close" @click="showConfirmListBtnGuide = false;"></span>
                </div>
                <div class="show-confirm-list-modal" v-if="showConfirmListBtnGuide"></div>
            </div>
            <!-- 再次操作确认进入面试列表相关代码 end -->
            <table-title
                class="floating-list-table-title"
                :isFloatingList="true"
                :title-store="titleStore"
                :table-title="tableTitleJson"
                @table-title="handleTableTitle"
            ></table-title>
        </div>

        <el-table
            border
            height="700"
            style="width:100%"
            ref="multipleTable"
            tooltip-effect="light"
            :data="tableData"
            v-loading="tableDataLoading"
            :row-class-name="rowClassName"
            @selection-change="handleSelectionChange"
            @expand-change="handleExpandChange">
            <el-table-column
                type="selection"
                fixed
                width="40"
            ></el-table-column>
            <el-table-column
                fixed
                align="left"
                width="150"
                prop="recommendDate"
                class-name="time-sort-cell">
                <template slot="header" slot-scope="scope">
                    <el-dropdown trigger="click" class="time-sort-dropdown">
                        <span class="el-dropdown-link">
                            <!-- {{sortRule.prop === 1 ? '推荐时间' : '状态变更时间' }} -->
                            <template v-if="sortRule.prop === 1">推荐时间倒序</template>
                            <template v-if="sortRule.prop === 2">状态变更时间倒序</template>
                            <template v-if="sortRule.prop === 3">推荐时间正序</template>
                            <template v-if="sortRule.prop === 4">状态变更时间正序</template>
                            <i v-show="tableData.length > 0" class="el-icon-arrow-down el-icon--right"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown" class="recommend-time-dropdown">
                            <el-dropdown-item 
                                :class="{'checked' : sortRule.prop === 1}"
                                @click.native="sortRuleChange(1)">推荐时间倒序
                            </el-dropdown-item>
                            <el-dropdown-item 
                                :class="{'checked' : sortRule.prop === 2}"
                                @click.native="sortRuleChange(2)">状态变更时间倒序
                            </el-dropdown-item>
                            <el-dropdown-item 
                                :class="{'checked' : sortRule.prop === 3}"
                                @click.native="sortRuleChange(3)">推荐时间正序
                            </el-dropdown-item>
                            <el-dropdown-item 
                                :class="{'checked' : sortRule.prop === 4}"
                                @click.native="sortRuleChange(4)">状态变更时间正序
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </template>
                <template slot-scope="scope">
                    <span v-if="sortRule.prop === 1">
                        {{ scope.row.recommendDate | formatDate }}
                    </span>
                    <span v-if="sortRule.prop === 2">
                        {{ scope.row.flowUpdateDate | formatDate }}
                    </span>
                    <span v-if="sortRule.prop === 3">
                        {{ scope.row.recommendDate | formatDate }}
                    </span>
                    <span v-if="sortRule.prop === 4">
                        {{ scope.row.flowUpdateDate | formatDate }}
                    </span>
                </template>
            </el-table-column>
            <el-table-column
                align="center"
                class-name="expand-cell"
                width="18"
                fixed
                type="expand">
                <template slot-scope="props">
                    <recommendDynamic
                        :index="+props.$index" 
                        @show-dialog="(index, nextStatus, recommend, btnText) => showRecommendDialog(index, nextStatus, recommend, btnText, 'expandRow')"
                        recommend-position="expandRow"
                        :recommendation="props.row"
                        :list-data="expandDataObj[props.row.id]"
                        @show-performance-dialog="showPerformanceDialog"
                    ></recommendDynamic>
                </template>
            </el-table-column>
            <el-table-column
                align="left"
                :show-overflow-tooltip="true"
                width="100"
                fixed
                class-name="name-cell"
                prop="candidateName">
                <template slot="header">
                    <selectPopover
                        :enableSearch="true"
                        :selectItem="[]"
                        type="SearchOnly"
                        :label="'candidateName'"
                        :title="'姓名'"
                        popover-class="header-name-popover"
                        :enableCount="true"
                        :arrowShow="tableData.length > 0"
                        @selectChange="selectDataChange"
                    ></selectPopover>
                </template>
                <template slot-scope="scope">
                    <span class="icon-market" v-show="scope.row.isPublic"></span>
                    <span class="await-confirm" v-show="scope.row.status === 64 && ((scope.row.isPublisher && scope.row.isRecommender) || ((scope.row.interviewConfirmedStatus === 3 && !scope.row.isPublisher) || (scope.row.interviewConfirmedStatus === 4 && !scope.row.isRecommender)))"></span>
                    <!-- <a v-if="!scope.row.isPublic" class="candidate-link" target="_blank"
                       :href="'/Headhunting/MyCompany.html#/candidateDetail/' + scope.row.candidateId + '/recommendation/' + scope.row.id">
                        {{scope.row.candidateName}}
                        <span class="hr-tag" v-if="scope.row.jobType == 3">HR</span>
                        <span class="operation-tag" v-if="scope.row.jobType == 5">代运营</span>
                    </a> -->
                    <a v-if="!scope.row.isPublic" class="candidate-link" target="_blank"
                       :href="'/#/candidateDetail/' + scope.row.candidateId + '/recommendation/' + scope.row.id">
                        {{scope.row.candidateName}}
                        <span class="hr-tag" v-if="scope.row.jobType == 3">HR</span>
                        <span class="operation-tag" v-if="scope.row.jobType == 5">代运营</span>
                    </a>
                    <!-- <a v-if="scope.row.isPublic" class="candidate-link" target="_blank"
                       :href="`/Headhunting/MyCompany.html#/recommendation/${scope.row.id}/recommendationList`">
                        {{scope.row.candidateName}}
                    </a> -->
                    <a v-if="scope.row.isPublic" class="candidate-link" target="_blank"
                       :href="`/#/recommendation/${scope.row.id}/recommendationList`">
                        {{scope.row.candidateName}}
                    </a>
                </template>
            </el-table-column>
            <el-table-column
                v-for="(item, index) in titleStore.slice(2, titleStore.length-1)"
                :key="item + index"
                :label="tableTitleMap[item].label"
                :label-class-name="tableTitleMap[item].labelClassName"
                :min-width="tableTitleMap[item].minWidth"
                :show-overflow-tooltip="tableTitleMap[item].showOverflowTooltip">
                <template slot="header">
                    <!-- <template v-if="tableTitleMap[item].isHeaderSelect">
                        <select-popover
                            :maxSelect="5"
                            :enableCount="true"
                            :title="tableTitleMap[item].label"
                            :label="tableTitleMap[item].name"
                            :enableSearch="tableTitleMap[item].headerSelectEnableSearch"
                            :selectItem="headerFilter[tableTitleMap[item].name]"
                            :defaultSelectedItems="tableTitleMap[item].hasDefaultSelectedItems ? defaultSelect[tableTitleMap[item].name] : []"
                            @selectChange="selectDataChange"
                        ></select-popover>
                    </template v-else> -->
                    <template>
                        <div class="table-slot">
                            {{tableTitleMap[item].label}}
                        </div>
                    </template>
                </template>
                
                <template slot-scope="scope">
                    <template v-if="tableTitleMap[item].isSimpleText">
                        <!-- {{scope.row[tableTitleMap[item].name] || '—'}} -->
                        <template v-if="tableTitleMap[item].name == 'job'">
                            <a class="job-link" :href="`/#/Job/${scope.row.jobId}/recommendation/${scope.row.id}?recommendDate=${scope.row.recommendDate}`" target="_blank">{{scope.row[tableTitleMap[item].name] || '—'}}</a>
                        </template>
                        <template v-else>
                            {{scope.row[tableTitleMap[item].name] || '—'}}
                        </template>
                    </template>
                    <template v-else>
                        <template v-if="item == 'yearOfExperience'">
                            <div class="table-slot table-slot-content">
                                {{ scope.row.yearOfExperience + '年' }}
                            </div>
                        </template>
                        <!-- 我的推荐 -->
                        <template v-if="item == 'recommendPM'">
                            <div class="center recommend-avatar">
                                <!-- <el-popover
                                    v-if="scope.row.jobType == 3"
                                    placement="top-start"
                                    width="250"
                                    trigger="hover"
                                    @show="contactPublisher(scope.row.jobOwnerId)">
                                    <div class="hr-contact-card" v-loading="contactLoading">
                                        <div class="hr-info">
                                            <img :src="scope.row.jobOwnerAvatarUrl | avatarUrlFilter" alt="">
                                            <div class="hr-base-info">
                                                <p class="hr-name" :title="hrInfo.fullName">{{ hrInfo.fullName }}</p>
                                                <p class="hr-phone">{{ hrInfo.mobile }}</p>
                                            </div>
                                        </div>
                                        <div class="hr-qr-code">
                                            <img :src="hrInfo.wechatQRCodeUrl" alt="">
                                        </div>
                                    </div>
                                    <img slot="reference" class="hr-img" :src="scope.row.jobOwnerAvatarUrl | avatarUrlFilter" alt="">
                                </el-popover> -->
                                <avatar
                                    :userId="scope.row.jobOwnerId"
                                    :enableLink="false"
                                    :src="scope.row.jobOwnerAvatarUrl | avatarUrlFilter"
                                    size="sm"
                                    :shareData="getShareData(scope.row)"
                                ></avatar>
                            </div>
                        </template>
                        <!-- 收到的推荐 -->
                        <template v-if="item == 'recommender'">
                            <div class="center recommend-avatar">
                                <avatar
                                    :userId="scope.row.ownerId"
                                    :enableLink="false"
                                    :src="scope.row.recommenderAvatarUrl | avatarUrlFilter"
                                    size="sm"
                                    :shareData="getShareData(scope.row)"
                                ></avatar>
                            </div>
                        </template>
                        <template v-if="item == 'lastestStatus'">
                            <span v-if="filterIndex == 1 && scope.row.status == 0 ">
                                推荐待反馈
                            </span>
                            <span v-else>
                                {{scope.row.status | formatStatus(scope.row)}}
                            </span>
                        </template>
                    </template>
                </template>
            </el-table-column>
            <el-table-column
                label="操作"
                :width="isShrink ? '80' : '280'"
                fixed="right"
                class-name="btn-menu"
                prop="ownerId">
                <template slot="header">
                    <div class="table-slot">
                        操作
                    </div>
                </template>
                <template slot-scope="scope">
                    <recommend-operation
                        v-if="scope.row.canOperate" 
                        @show-dialog="(index, nextStatus, recommend, btnText) => showRecommendDialog(index, nextStatus, recommend, btnText, 'tableRow')"
                        recommend-position="tableRow"
                        :index="scope.$index" 
                        :isShrink="isShrink"
                        :recommend="scope.row" 
                        :type="scope.row.status"
                        :tableType="filterIndex"
                        @show-performance-dialog="showPerformanceDialog"
                        @show-business-classfication-dialog="showBusinessClassficationDialog"
                    ></recommend-operation>
                </template>
            </el-table-column>
            <template slot="empty">
                <div>
                    <span class="empty-img"></span>
                    <p class="empty-data">暂无数据</p>
                </div>
            </template>
        </el-table>
        <table-page-tab
            :filterIndex="filterIndex"
            :pageTabs="pageTabJson"
            :unApplyNumber="unApplyNumber"
        ></table-page-tab>
        <div class="work-table-footer">
            <div class="footer-left">
                <div class="footer-select">
                    <input hidden v-model="allSelect" type="checkbox">
                    <span class="all-select-checkbox" @click="handleAllSelect"></span>
                    <span>全选</span>
                    <span class="select-count">已选择
                        <span class="color-orange">{{multipleCount}}</span>
                        条记录
                    </span>
                </div>
                <button
                    class="work-table-btn work-table-btn-primary"
                    :disabled="acceptBtnCanClick"
                    v-if="filterIndex == 0"
                    @click="handleAcceptClick"
                >
                    接受推荐
                </button>
                <button
                    class="work-table-btn work-table-btn-orange"
                    :disabled="multipleCount==0"
                    @click="handleRemoveClick"
                >
                    移除
                </button>
            </div>
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="page.current + 1"
                :page-sizes="[20, 30, 50]"
                :page-size="page.size"
                class="el-pagination-workTable"
                layout="total, sizes, prev, pager, next, slot"
                :total="page.total">
                <span class="pagination-text">
                    <span>前往<el-input v-model="pagerJump" @keyup.enter.native="handlePagerJump"></el-input>页</span>
                    <span @click="handlePagerJump">跳转</span>
                </span>
            </el-pagination>

        </div>
        <accept-modal
            ref="acceptModal"
            @accept-success="batchOperateSuccess"
            :table-name="tableName"
        ></accept-modal>
        <remove-modal
            ref="removeModal"
            @remove-success="batchOperateSuccess"
            :table-name="tableName"
            :type="filterIndex"
        ></remove-modal>
        <todo-modal></todo-modal>
        
        <recommend-dialog-list 
            ref="recommendDialog" 
            @update-list="updateList"
            @edit-update-list="editUpdateList"
        ></recommend-dialog-list>

        <!-- 编辑业务分类弹窗 -->
        <business-classfication-dialog
            ref="businessClassficationDialog"
            @refresh="refresh"
         />
    </div>
</div>
</template>

<script>
    import moment from 'moment';
    import {recommendHashMap} from '#/js/config/recommend-detail.json';
    import selectPopover from '#/component/common/select-popover.vue';
    import recommendOperation from '#/component/workTable/recommend-operation.vue';
    import recommendDynamic from '#/component/workTable/recommend-dynamic.vue';
    import floatingListBubble from './floatingListBubble.vue';
    import TablePageTab from '#/component/workTable/table-page-tab.vue';
    import acceptModal from '../component/floatingListAcceptModal';
    import removeModal from '../component/floatingListRemoveModal';
    import recommendDialogList from '#/component/workTable/recommend-dialog-list.vue';
    import Avatar from '#/component/common/avatar.vue';
    import todoModal from '../component/floatingListTodoModal';
    import TableTitle from '#/component/common/tableTitle.vue'
    import { FloatingList as tableTitleMap } from '#/js/config/tableTitleMap.json';
    import floatingListService from '#/js/service/floatingListService.js';
    import myService from '#/js/service/myService.js';
    import invoiceSubFlowStatus from '#/js/config/invoiceSubFlowStatus.js';
    import emitter from '@src/js/mixins/emitter';
    import BusinessClassficationDialog from '#/component/common/dialog/business-classfication-dialog.vue';

    const today = moment(new Date());

    export default {
        name: "workTable",
        mixins: [emitter],
        components: {
            selectPopover,
            recommendOperation,
            recommendDynamic,
            floatingListBubble,
            TablePageTab,
            recommendDialogList,
            acceptModal,
            removeModal,
            todoModal,
            Avatar,
            TableTitle,
            BusinessClassficationDialog,
        },
        computed: {
            multipleCount() {
                return this.multipleSelection.length;
            },
            acceptSelection() {
                return this.multipleSelection.filter((item) => {
                    return item.status == 0 || item.status == '0';
                })
            },
            acceptBtnCanClick() {
                return this.acceptSelection.length != this.multipleCount || this.acceptSelection.length == 0
            },
            allSelect() {
                return this.tableData.length > 0 ? this.tableData.length == this.multipleSelection.length : false;
            },
            tableName() {
                return this.filterIndex == 0 ? 'RecommendToMe' : 'MyRecommend';
            },
            isCFUser() {
                return this.$store.state.user.userInfo.isCFUser;
            },
            userInfo() {
                return this.$store.state.user.userInfo;
            },
            // 是PM还是财务人员
            isFinancial () {
                return this.userInfo.privilegeCodeList && this.userInfo.privilegeCodeList.includes('Financial');
            },
            isOfferList () {
                return this.userInfo.privilegeCodeList && this.userInfo.privilegeCodeList.includes('OfferList');
            },
            pageTabJson() {
                let pageTabs = [];
                if (this.isFinancial) {
                    pageTabs = [
                        {
                            enTitle: "Floating List",
                            cnTitle: "流程管理",
                            path: "/floatingList/unApply",
                            title: "未申请的开票",
                            tabIndex: 2,
                            aliveComponent: "FloatingListUnApplyList",
                        },
                        {
                            enTitle: "Floating List",
                            cnTitle: "流程管理",
                            path: "/floatingList/apply",
                            title: "开票申请",
                            tabIndex: 3,
                            aliveComponent: "FloatingListApplyList",
                        },
                    ]; 
                } else {
                    pageTabs = [
                        {
                            enTitle: "Floating List",
                            cnTitle: "流程管理",
                            path: "/floatingList",
                            title: "收到的推荐",
                            tabIndex: 0,
                            aliveComponent: "FloatingListRecommendToMe",
                        },
                        {
                            enTitle: "Floating List",
                            cnTitle: "流程管理",
                            path: "/floatingList/myRecommend",
                            title: "我的推荐",
                            tabIndex: 1,
                            aliveComponent: "FloatingListMyRecommend",
                        },
                        // {
                        //     enTitle: "Floating List",
                        //     cnTitle: "流程管理",
                        //     path: "/floatingList/unApply",
                        //     title: "未申请的开票",
                        //     tabIndex: 2,
                        //     aliveComponent: "FloatingListUnApplyList",
                        // },
                        {
                            enTitle: "Floating List",
                            cnTitle: "流程管理",
                            path: "/floatingList/apply",
                            title: "开票申请",
                            tabIndex: 3,
                            aliveComponent: "FloatingListApplyList",
                        },
                        {
                            enTitle: "Floating List",
                            cnTitle: "流程管理",
                            path: "/floatingList/recommendAudit",
                            title: "HR直招推荐审批",
                            tabIndex: 5,
                            aliveComponent: "FloatingListRecommendAudit",
                        },
                    ];
                }

                if(this.isOfferList) {
                    const tabOjb = {
                            enTitle: "Floating List",
                            cnTitle: "流程管理",
                            path: "/floatingList/offerList",
                            title: "Offer列表",
                            tabIndex: 4,
                            aliveComponent: "FloatingListOfferList",
                        };
                    pageTabs.push(tabOjb);
                }
                return pageTabs;
            },
        },
        data() {
            return {
                currentIndex: undefined,
                contactLoading: false,
                hrInfo: {},
                titleStore: [],
                isTimeChange:false,
                timeChangedBubble:{},
                isShrink: false,
                tableDataLoading: false,
                sortRule: {
                    prop: 1
                },
                statusBubbleCount: [],
                selectLabelMap: {
                    degree: '学历',
                    status: '最新状态',
                    customer: '推荐至客户',
                    company: '当前公司',
                    title: '当前职位',
                    yearOfExperience: '工作年限',
                    job: '推荐至职位',
                    candidateName: '姓名'
                },
                selectData: {
                    degree: [],
                    status: [],
                    customer: [],
                    company: [],
                    title: [],
                    yearOfExperience: [],
                    job: [],
                    candidateName: []
                },
                page: {
                    total: 0,
                    current: 0,
                    size: 20,
                },
                searchTime: {
                    endDate: today.format('YYYY-MM-DD'),
                    // startDate: moment(new Date().setTime(new Date().getTime() - 7 * 24 * 60 * 60 * 1000)).format('YYYY-MM-DD'),
                    startDate: moment().subtract(1, 'months').format("YYYY-MM-DD"),
                },
                sortRecommendDateList: [],
                sortFlowUpdateDateList: [],
                tableData: [],
                expandDataObj: {},
                orderPageinationTable: [],
                headerFilter: {
                    sortRule: [
                        {
                            label: '推荐时间倒序',
                            isSelect: false,
                        },
                        {
                            label: '状态变更时间倒序',
                            isSelect: false,
                        },
                        {
                            label: '推荐时间正序',
                            isSelect: false,
                        },
                        {
                            label: '状态变更时间正序',
                            isSelect: false,
                        }
                    ],
                    degree: [
                        {
                            label: '博士',
                            count: 0,
                            isSelect: false,
                        },
                        {
                            label: '硕士',
                            count: 0,
                            isSelect: false,
                        },
                        {
                            label: 'MBA',
                            count: 0,
                            isSelect: false,
                        },
                        {
                            label: '本科',
                            count: 0,
                            isSelect: false,
                        },
                        {
                            label: '大专',
                            count: 0,
                            isSelect: false,
                        },
                        {
                            label: '高中及以下',
                            count: 0,
                            isSelect: false,
                        },
                        {
                            label: '未知',
                            count: 0,
                            isSelect: false,
                        }
                    ],
                    status: [
                        {
                            label: this.filterIndex == 0 ? '未处理' : '推荐待反馈',
                            code: 0,
                            isSelect: false,
                            count: 0
                        },
                        {
                            label: '已接受待推进面试',
                            code: 1,
                            isSelect: false,
                            count: 0
                        },
                        {
                            label: '推荐拒绝',
                            code: 2,
                            isSelect: false,
                            count: 0
                        },
                        {
                            label: '面试待确认',
                            code: 64,
                            isSelect: false,
                            count: 0
                        },
                        {
                            label: '面试待安排',
                            code: 4,
                            isSelect: false,
                            count: 0
                        },
                        {
                            label: '面试已安排',
                            code: 5,
                            isSelect: false,
                            count: 0
                        },
                        {
                            label: '面试已反馈',
                            code: 407,
                            isSelect: false,
                            count: 0
                        },
                        {
                            label: 'S1全部面试通过',
                            code: 7,
                            isSelect: false,
                            count: 0
                        },
                        {
                            label: 'S2提交流水等材料',
                            code: 9,
                            isSelect: false,
                            count: 0
                        },
                        {
                            label: 'S3客户审批offer',
                            code: 10,
                            isSelect: false,
                            count: 0
                        },
                        {
                            label: 'S4沟通offer细节',
                            code: 11,
                            isSelect: false,
                            count: 0
                        },
                        {
                            label: 'S5等待接受offer',
                            code: 12,
                            isSelect: false,
                            count: 0
                        },
                        {
                            label: 'S6双方确认offer',
                            code: 13,
                            isSelect: false,
                            count: 0
                        },
                        {
                            label: '已offer',
                            code: 8,
                            isSelect: false,
                            count: 0
                        },
                        {
                            label: '入职',
                            code: 16,
                            isSelect: false,
                            count: 0
                        },
                        {
                            label: '离职',
                            code: 32,
                            isSelect: false,
                            count: 0
                        },
                        {
                            label: '淘汰',
                            code: 1024,
                            isSelect: false,
                            count: 0
                        },
                        {
                            label: '开票中',
                            code: 24,
                            isSelect: false,
                            count: 0
                        },
                        {
                            label: '已回款',
                            code: 26,
                            isSelect: false,
                            count: 0
                        },
                    ],
                    customer: [],
                    company: [],
                    title: [],
                    yearOfExperience: [
                        {
                            label: '10年以上',
                            regFunction: (yearOfExp) => {
                                return yearOfExp >= 10;
                            },
                            isSelect: false,
                            count: 0
                        },
                        {
                            label: '5-10年',
                            regFunction: (yearOfExp) => {
                                return yearOfExp >= 5 && yearOfExp < 10;
                            },
                            isSelect: false,
                            count: 0
                        },
                        {
                            label: '3-5年',
                            regFunction: (yearOfExp) => {
                                return yearOfExp >= 3 && yearOfExp < 5;
                            },
                            isSelect: false,
                            count: 0
                        },
                        {
                            label: '3年以内',
                            regFunction: (yearOfExp) => {
                                return yearOfExp < 3;
                            },
                            isSelect: false,
                            count: 0
                        },
                        {
                            label: '未知',
                            regFunction: (yearOfExp) => {
                                return yearOfExp === '未知';
                            },
                            count: 0,
                            isSelect: false,
                        }
                    ],
                    job: []
                },
                multipleSelection: [],
                candidateTotal: 0,
                defaultSelect:{
                    customer:[],
                    job:[],
                    status:[]
                },
                currentLabel: '',
                isResetBubble: true,
                defaultSortStatus: false,
                pagerJump: 0,
                // pageTabJson: [{
                //     enTitle: "Floating List",
                //     cnTitle: "流程管理",
                //     path: "/floatingList/myRecommend",
                //     title: "我的推荐",
                //     tabIndex: 1,
                //     aliveComponent: 'FloatingListMyRecommend'
                // }, {
                //     enTitle: "Floating List",
                //     cnTitle: "流程管理",
                //     path: "/floatingList",
                //     title: "收到的推荐",
                //     tabIndex: 0,
                //     aliveComponent: 'FloatingListRecommendToMe'
                // }],
                tableTitleMap: tableTitleMap,
                isShowConfirmListBtn: false,
                showConfirmListBtnGuide: false,
                filterStore: ['degree', 'yearOfExperience', 'title', 'company', 'recommendTitle','recommendCustomer', 'lastestStatus' ],
                aCurrentLabel: [],

                unApplyNumber: 0,
            };
        },
        props: {
            filterIndex: 0,
            tableTitleJson:{
                type:Array,
                default:[]
            }
        },
        filters: {
            formatDate(string) {
                return moment(string).format('YYYY-MM-DD');
            },
            formatStatus(status, row) {
                if([24, 26].includes(status)) {
                    return invoiceSubFlowStatus[row.flowStepName];
                // } else if([4, 5, 74].includes(status)) {
                //     // s0安排面试包括 面试待安排，面试已安排，面试已反馈
                //     return `S0安排面试`;
                } else {
                    return status == 6 ? recommendHashMap['5'].text : recommendHashMap[String(status)]?.text;
                }
            },
            labelsTip(labels) {
                let tip = [];
                labels.forEach(item => {
                    tip.push(item.label)
                });
                return tip.join(' , ');
                ;
            },
            avatarUrlFilter(url) {
                if(url) {
                    url = url.replace(/^https?:/, location.protocol);
                }
                return url;
            }
        },
        created() {
            // this.init();
            window.floatingTable = this;
        },
        activated() {},
        updated() {},
        mounted() {
            let _this = this;
            _this.resize();
            window.onresize = function () {
                _this.resize();
            }
            this.tableTitleInit();
            if (window.location.href.indexOf('time') != -1) {
                //从非工作台页面的顶部横条消息提醒进入
                this.defaultSortStatus = true;
                this.$refs.bubble.handleCommand({
                    name: '最近一个月',
                    value: 30
                })
            } else if (this.$route.query.status != null && this.$route.query.status != 'undefined') {
                this.defaultSortStatus = true;
                //从工作台页面的系统消息提醒进入
                this.$refs.bubble.handleCommand({
                    name: '全部',
                    value: 0
                })
            } else if(window.location.href.indexOf('statusType') > -1){
                //从职位详情页跳转
                this.$refs.bubble.handleCommand({
                    name:'全部',
                    value:0
                })
            } else if(this.$route.query.from == 'systemObsoletePrompt') {
                // 从系统消息推荐流程未offering的长时间未更新淘汰提醒进入
                // 时间筛选为最近三个月；时间排序为状态变更时间正序
                this.sortRule.prop = 4;
                this.$refs.bubble.handleCommand({
                    name: '最近三个月',
                    value: 90
                })
            } else {
                //this.initDefalutSelectSearch();
                this.init();
            }

            let today = new Date(new Date().toLocaleDateString()).getTime();
            if(today < new Date('2021/03/01 00:00:00').getTime()) {
                this.isShowConfirmListBtn = true;
                if(!localStorage.getItem('confirmListBtnGuide')) {
                    this.showConfirmListBtnGuide = true;
                    localStorage.setItem('confirmListBtnGuide', true);
                }
            }
        },
        methods: {
            showBusinessClassficationDialog(recommend) {
                // console.log(recommend);
                this.$refs.businessClassficationDialog.show(recommend);
            },
            refresh() {
                this.init();
            },
            contactPublisher(userId) {
                console.log(userId)
                this.dialogVisible = true;
                this.contactLoading = true;
                _request({
                    url: '/openapi/User/%p/GetWechatQRCodeInfo'.replace(/%p/g, userId),
                    method: "GET",
                    baseURL: "LbdOpenApi",
                }).then((res) => {
                    console.log(res);
                    this.hrInfo = res;
                }).catch((err) => {
                    console.log(err)
                }).finally(() => {
                    this.contactLoading = false;
                })
            },

            tableTitleInit(){
                let tableName = this.filterIndex == 0 ? 'recommendToMe' : 'myRecommend';
                if (localStorage.getItem(`${tableName}TableTitleNew`)) {
                    this.titleStore = JSON.parse(localStorage.getItem(`${tableName}TableTitleNew`));
                } else {
                    // this.filterIndex == 1 为我的推荐报表，this.filterIndex == 0 为我收到的推荐报表
                    if(this.isCFUser) {
                        this.titleStore = this.filterIndex == 1 ? ['recommendTime', 'realName', 'location', 'school', 'degree', 'yearOfExperience', 'title', 'company', 'businessClassificationName', 'recommendTitle', 'recommendCustomer', 'recommendPM', 'lastestStatus', 'operation'] :
                        ['recommendTime', 'realName', 'location', 'school', 'degree', 'yearOfExperience', 'title', 'company', 'businessClassificationName', 'recommendTitle', 'recommendCustomer', 'recommender', 'lastestStatus', 'operation'];
                    } else {
                        this.titleStore = this.filterIndex == 1 ? ['recommendTime', 'realName', 'location', 'school', 'degree', 'yearOfExperience', 'title', 'company', 'recommendTitle', 'recommendCustomer', 'recommendPM', 'lastestStatus', 'operation'] :
                        ['recommendTime', 'realName', 'location', 'school', 'degree', 'yearOfExperience', 'title', 'company', 'recommendTitle', 'recommendCustomer', 'recommender', 'lastestStatus', 'operation'];
                    }
                }
            },
            init() {
                this.tableDataLoading = true;

                floatingListService
                    .getFloatingList({
                        filter: this.filterIndex === 0 ? 2 : 1,
                        startDate: this.searchTime.startDate,
                        endDate: this.searchTime.endDate
                    })
                    .then(res => {
                        this.page.total = res.total;
                        this.candidateTotal = res.candidateTotal;
                        if (res.items.length > 0) {
                            this.orderDate = res.items;
                            this.sortRecommendDateList = [], this.sortFlowUpdateDateList = [];
                            // 时间类型选择（推荐时间倒序、状态变更时间倒序、推荐时间正序、状态变更时间正序）
                            if (this.sortRule.prop === 1) {
                                this.sortRecommendDate(1);
                            } else if(this.sortRule.prop === 2) {
                                this.sortFlowUpdateDate(2);
                            } else if(this.sortRule.prop === 3) {
                                this.sortRecommendDate(3);
                            } else {
                                this.sortFlowUpdateDate(4);
                            }
                            this.selectDataChange();
                            this.getHeaderFilter();
                            this.initDefalutSelectSearch();
                        } else {
                            this.tableData = [];
                            this.sortRecommendDateList = [];
                            this.sortFlowUpdateDateList = [];
                            this.orderPageinationTable = [];
                        }
                        this.defaultAction();
                        this.timeChangedBubbleCount();
                        this.tableDataLoading = false;
                    }, err => {
                        this.tableDataLoading = false;
                    })
                // 获取未申请的条数
                this.getUnapplyNumber();
            },
            resize() {
                if ($('.tab-view').width() < 1344) {
                    this.isShrink = true;
                } else {
                    this.isShrink = false;
                }
            },
            rowClassName(item) {
                let status = item.row.status;
                if (status === 1024 || status === 32 || status === 2) {
                    return 'lazy';
                } else {
                    return ''
                }
            },
            selectDataChange(label, result) {
                // 处理筛选选中后高亮或者取消高亮
                const hashMap = {
                    job: 'recommendTitle',
                    customer: 'recommendCustomer',
                    status: 'lastestStatus',
                };
                const _label = hashMap[label] || label;
                if(result && result.length >0) {
                    if(!this.aCurrentLabel.includes(_label)){
                        this.aCurrentLabel.push(_label);
                    }
                } else {
                    const nIdx = this.aCurrentLabel.findIndex(item => item == _label);
                    if(nIdx >=0) this.aCurrentLabel.splice(nIdx, 1);
                }
                

                // let arr = this.sortRule.prop === 1 ? this.sortRecommendDateList : this.sortFlowUpdateDateList;
                let arr = (this.sortRule.prop === 1 || this.sortRule.prop === 3) ? this.sortRecommendDateList : this.sortFlowUpdateDateList;
                let resultArr = [];
                let filterStatus = false;
                this.currentLabel = label;
                if (label) {
                    _tracker.track("Filter", JSON.stringify({
                        "tableName": this.tableName,
                        "type": "TableHeader",
                        "field": label,
                        "text": this.selectLabelMap[label]
                    }))
                    this.selectDataArrange(label, result);
                }

                if (label === 'status') {
                    this.$refs.bubble.clearBubble();
                    //若表头状态仅一项被选中, 则对应的气泡也要展示选中样式
                    if (result.length == 1) {
                        if([7,9,10,11,12,13].includes(result[0].code)) {
                            // 8是offer中，也就是进入offer的第一个子状态
                            this.$refs.bubble.currentBubble = 7;
                        } else {
                            this.$refs.bubble.currentBubble = result[0].code;
                        }
                    }
                    // 处理offer中同时选择多个子状态
                    if(result.length > 1) {
                        console.log(result.every(item => [8,9,10,11,12,13].includes(item.code)))
                        if(result.every(item => [7,9,10,11,12,13].includes(item.code))){
                            this.$refs.bubble.currentBubble = 7;
                        }
                    }
                }
                for (let key in this.selectData) {
                    if (this.selectData[key].length !== 0) {
                        filterStatus = true;
                    }
                }
                if (!filterStatus) {
                    return this.orderPageinationTable = arr;
                }

                for (let item in arr) {
                    let status = true;
                    for (let key in this.selectData) {
                        if (!status) {
                            continue;
                        }
                        switch (key) {
                            case 'yearOfExperience':
                                if (this.selectData[key].length > 0) {
                                    status = false;
                                }
                                for (let filter in this.selectData[key]) {
                                    if (this.selectData[key][filter].regFunction(arr[item].yearOfExperience)) {
                                        status = true;
                                        continue;
                                    }
                                }
                                break;
                            case 'status':
                                if (this.selectData[key].length > 0) {
                                    status = false;
                                }
                                for (let filter in this.selectData[key]) {
                                    if(this.selectData[key][filter].code != 7) {
                                        if (this.selectData[key][filter].code === arr[item][key] ||
                                        (arr[item][key] == 6 && this.selectData[key][filter].code == 5)) {
                                            status = true;
                                            continue;
                                        }
                                    } else {
                                        // 407, 
                                        if([7 ,9,10,11,12,13].includes(arr[item][key])) {
                                            status = true;
                                            continue;
                                        }
                                    }
                                }
                                break;
                            default:
                                if (this.selectData[key].length > 0) {
                                    status = false;
                                }
                                for (let filter in this.selectData[key]) {
                                    if(key !== 'candidateName' ){
                                        if (this.selectData[key][filter].label === arr[item][key]) {
                                            status = true;
                                            continue;
                                        }
                                    } else {
                                        if (arr[item][key].indexOf(this.selectData[key][filter].label) !== -1) {
                                            status = true;
                                            continue;
                                        }
                                    }
                                }
                                break;
                        }
                    }
                    if (status) {
                        resultArr.push(arr[item]);
                    }
                }
                return this.orderPageinationTable = resultArr;
            },
            clearSelect(label) {
                // 处理取消Label过滤条件不高亮 start
                const hashMap = {
                    job: 'recommendTitle',
                    customer: 'recommendCustomer',
                    status: 'lastestStatus',
                };
                const _label = hashMap[label] || label;
                const _nCancelLabelIndex = this.aCurrentLabel.indexOf(_label);
                if(_nCancelLabelIndex >=0 ) {
                    this.aCurrentLabel.splice(_nCancelLabelIndex, 1);
                }
                // 处理取消Label过滤条件不高亮 end


                for (let key in this.headerFilter[label]) {
                    this.headerFilter[label][key].isSelect = false;
                }
                if (label == 'status') {
                    this.$refs.bubble.clearBubble();
                }

                this.currentLabel = label === this.currentLabel ? '' : this.currentLabel;
                this.selectData[label] = [];
                this.selectDataChange()
            },
            getHeaderFilter() {
                let hashTable = {
                    degree: {},
                    title: {},
                    job: {},
                    company: {},
                    customer: {},
                    yearOfExperience: {},
                    status: {},
                };
                let arrayTable = {
                    degree: [],
                    title: [],
                    job: [],
                    company: [],
                    customer: [],
                    yearOfExperience: [],
                    status: [],
                    candidateTotal: []
                };
                const candidateTotal = new Set();
                let orderData = this.orderPageinationTable;
                this.initHeaderFilter();
                for (let key in orderData) {
                    // 学历聚合
                    if (orderData[key].degree && this.currentLabel !== 'degree') {
                        this.headerFilter['degree'].map((item) => {
                            if (item.label == orderData[key].degree) {
                                item.count++;
                            }
                        })
                    }

                    // 当前职位聚合
                    if (orderData[key].title && this.currentLabel !== 'title') {
                        if (!hashTable['title'][orderData[key].title]) {
                            hashTable['title'][orderData[key].title] = {};
                            hashTable['title'][orderData[key].title].status = true;
                            hashTable['title'][orderData[key].title].data = {
                                label: orderData[key].title,
                                isSelect: this.selectData['title'].length > 0,
                                count: 1
                            };
                            arrayTable.title.push(hashTable['title'][orderData[key].title].data)
                        } else {
                            hashTable['title'][orderData[key].title].data.count++;
                        }
                    }

                    // 推荐至职位
                    if (orderData[key].job && this.currentLabel !== 'job') {
                        if (!hashTable['job'][orderData[key].job]) {
                            hashTable['job'][orderData[key].job] = {};
                            hashTable['job'][orderData[key].job].status = true;
                            hashTable['job'][orderData[key].job].data = {
                                label: orderData[key].job,
                                isSelect: this.selectData['job'].length > 0,
                                count: 1
                            };
                            arrayTable.job.push(hashTable['job'][orderData[key].job].data)
                        } else {
                            hashTable['job'][orderData[key].job].data.count++;
                        }
                    }

                    // 当前公司聚合
                    if (orderData[key].company && this.currentLabel !== 'company') {
                        if (!hashTable['company'][orderData[key].company]) {
                            hashTable['company'][orderData[key].company] = {};
                            hashTable['company'][orderData[key].company].status = true;
                            hashTable['company'][orderData[key].company].data = {
                                label: orderData[key].company,
                                isSelect: this.selectData['company'].length > 0,
                                count: 1
                            };
                            arrayTable.company.push(hashTable['company'][orderData[key].company].data)
                        } else {
                            hashTable['company'][orderData[key].company].data.count++;
                        }
                    }

                    // 推荐至客户聚合
                    if (orderData[key].customer && this.currentLabel !== 'customer') {
                        if (!hashTable['customer'][orderData[key].customer]) {
                            hashTable['customer'][orderData[key].customer] = {};
                            hashTable['customer'][orderData[key].customer].status = true;
                            hashTable['customer'][orderData[key].customer].data = {
                                label: orderData[key].customer,
                                isSelect: this.selectData['customer'].length > 0,
                                count: 1
                            };
                            arrayTable.customer.push(hashTable['customer'][orderData[key].customer].data)
                        } else {
                            hashTable['customer'][orderData[key].customer].data.count++;
                        }
                    }

                    // 工作年限聚合
                    if (orderData[key].yearOfExperience && this.currentLabel !== 'yearOfExperience') {
                        this.headerFilter['yearOfExperience'].forEach((item) => {
                            if (item.regFunction(orderData[key].yearOfExperience)) {
                                item.count++;
                            }
                        })
                    }

                    // 状态聚合 
                    if (orderData[key].status !== undefined && this.currentLabel !== 'status') {
                        let status = orderData[key].status === 6 ? 5 : orderData[key].status;
                        if (!hashTable['status'][status]) {
                            hashTable['status'][status] = {
                                label: this.filterIndex === 1 && status === 0 ? '推荐待反馈' : recommendHashMap[String(status)]?.text,
                                isSelect: this.selectData['status'].length > 0,
                                count: 1,
                                code: status
                            };
                        } else {
                            hashTable['status'][status].count++;
                        }
                        this.headerFilter['status'].forEach((item) => {
                            if (item.code === status) {
                                item.count++;
                            }
                        })
                    }

                    //候选人聚合
                    orderData[key].candidateId ? candidateTotal.add(orderData[key].candidateId) : '';
                }
                for (let key in arrayTable) {
                    if (key === 'status' || key === 'degree' || key === 'yearOfExperience' || key === 'candidateTotal') {
                        continue;
                    }
                    this.currentLabel !== key ? this.setHeaderFilter(key,arrayTable[key]) : this.headerFilter[key];
                }
                //只有时间控件改变才会改变气泡数量
                if(this.isResetBubble) {
                    this.isResetBubble = false;
                    //要先初始化气泡数量
                    this.statusBubbleCount = [
                        {status: 0, name: this.filterIndex === 0 ? '未处理' : '推荐待反馈', count: 0},
                        {status: 1, name: '已接受待推进面试', count: 0},
                        {status: 64, name: '面试待确认', count: 0},
                        {status: 4, name: '面试待安排', count: 0},
                        {status: 2, name: '推荐拒绝', count: 0},
                        {status: 5, name: '面试已安排', count: 0},
                        // {status: 7, name: '面试已反馈', count: 0},
                        // {status: 8, name: 'S1全部面试通过', count: 0},
                        {status: 407, name: '面试已反馈', count: 0},
                        {status: 7, name: 'S1全部面试通过', count: 0},
                        {status: 8, name: '已offer', count: 0},
                        {status: 9, name: 'S2提交流水等材料', count: 0},
                        {status: 10, name: 'S3客户审批offer', count: 0},
                        {status: 11, name: 'S4沟通offer细节', count: 0},
                        {status: 12, name: 'S5等待接受offer', count: 0},
                        {status: 13, name: 'S6双方确认offer', count: 0},
                        {status: 16, name: '入职', count: 0},
                        {status: 32, name: '离职', count: 0},
                        {status: 1024, name: '淘汰', count: 0},
                        {status: 24, name: '开票中', count: 0},
                        {status: 26, name: '已回款', count: 0},
                    ];
                    this.statusBubbleCount.forEach((bubbleCount) => {
                        if (hashTable['status'][bubbleCount.status]) {
                            bubbleCount.count = hashTable['status'][bubbleCount.status].count;
                        }
                    });
                }
                return this.candidateTotal = candidateTotal.size;
            },
            setHeaderFilter(headerFilterKeyName, orderArray) {
                if(!orderArray) {
                    return false;
                }
                const arr = this.headerFilter[headerFilterKeyName];
                while(arr.length > 0){
                    arr.pop();
                }

                if(orderArray.length > 0){
                    orderArray.forEach(item => {
                        arr.push(item);
                    });
                } else {

                }
            },
            initHeaderFilter(){
                const label = ['status','yearOfExperience', 'degree'];
                label.forEach(item => {
                    if(item !== this.currentLabel) {
                        this.headerFilter[item].forEach(item => {
                            item.count = 0;
                        })
                    }
                })
            },
            sortRuleChange(type) {
                let text = '';
                if(type === 1) {
                    text = "推荐时间倒序";
                } else if(type === 2) {
                    text = "状态变更时间倒序";
                } else if(type === 3) {
                    text = "推荐时间正序";
                } else {
                    text = "状态变更时间正序";
                }
                _tracker.track("Filter", JSON.stringify({
                    "tableName": this.tableName,
                    "type": "SortDate",
                    // "text": type === 1 ? "推荐时间" : "状态变更时间"
                    "text": text
                }))

                let _this = this;
                if (type === 1) {
                    this.sortRule.prop = 1;
                    this.tableData = [];
                    this.sortRecommendDate(type);
                } else if(type === 2) {
                    this.sortRule.prop = 2;
                    this.tableData = [];
                    this.sortFlowUpdateDate(type);
                } else if(type === 3) {
                    this.sortRule.prop = 3;
                    this.tableData = [];
                    this.sortRecommendDate(type);
                } else {
                    this.sortRule.prop = 4;
                    this.tableData = [];
                    this.sortFlowUpdateDate(type);
                }
            },
            // 倒序排序
            sortDate(arr, keyName) {
                arr.sort(function (per, next) {
                    if (+moment(per[keyName]) < +moment(next[keyName])) {
                        return 1
                    } else if (+moment(per[keyName]) > +moment(next[keyName])) {
                        return -1
                    } else {
                        return 0
                    }
                })
                return arr;
            },
            // 正序排序
            sortOrderDate(arr, keyName) {
                arr.sort(function (per, next) {
                    if (+moment(per[keyName]) < +moment(next[keyName])) {
                        return -1
                    } else if (+moment(per[keyName]) > +moment(next[keyName])) {
                        return 1
                    } else {
                        return 0
                    }
                })
                return arr;
            },
            sortRecommendDate(type) {
                if(type === 1) { // 倒序
                    this.sortRecommendDateList = this.sortDate(this.orderDate,'recommendDate');
                } else { // 正序
                    this.sortRecommendDateList = this.sortOrderDate(this.orderDate,'recommendDate');
                }
                this.selectDataChange();
                return this.pageination();
            },
            sortFlowUpdateDate(type) {
                if(type === 2) { // 倒序
                    this.sortFlowUpdateDateList = this.sortDate(this.orderDate, 'flowUpdateDate');
                } else { // 正序
                    this.sortFlowUpdateDateList = this.sortOrderDate(this.orderDate, 'flowUpdateDate');
                }
                this.selectDataChange();
                return this.pageination();
            },
            pageination() {
                this.tableData = this.orderPageinationTable.slice(this.page.current * this.page.size, this.page.current * this.page.size + this.page.size);
                document.querySelector('.el-table__body-wrapper').scrollTop = 0;

                // 兼容el-table fixed 滑动对不齐
                const self = this;
                this.$nextTick(() => {
                    self.$refs.multipleTable.doLayout();
                });
                return this.page.total = this.orderPageinationTable.length;
            },
            handleSizeChange(size) {
                this.page.size = size;
                this.page.current = 0;
                return this.pageination();
            },
            handleCurrentChange(current) {
                this.page.current = (current - 1);
                return this.pageination();
            },
            // test() {
            //     let $el = $(window.event.srcElement).closest('.el-table__row');
            //     $el.after('<p>aasadfasdfadf</p>')
            // },
            handleSelectionChange(val) {
                this.multipleSelection = val;
            },
            handleAllSelect() {
                this.$refs.multipleTable.toggleAllSelection();
            },
            handleAcceptClick() {
                console.log(this.acceptSelection);
                floatingListService
                    .checkCandidate(this.acceptSelection.map(item => {
                        return item.id;
                    }))
                    .then(res => {
                        this.$refs.acceptModal.show(this.acceptSelection,res || []);
                    })

            },
            handleRemoveClick() {
                this.$refs.removeModal.show(this.multipleSelection);
            },
            timeLimitChange(param) {
                if(param.bubble.status !== ''){
                    this.selectData.status = [];
                    this.isTimeChange = true;
                    this.timeChangedBubble = param.bubble;
                }
                this.searchTime = param.time;
                this.isResetBubble = true;
            },
            timeChangedBubbleCount(){
                //气泡选中后改变时间后气泡聚合
                if (this.isTimeChange) {
                    this.isTimeChange = false;
                    let bubble = this.timeChangedBubble
                    this.bubbleClick(bubble);
                }
            },
            bubbleClick(param) {
                let status = true;
                for (let key in this.selectData.status) {
                    if (this.selectData.status[key].status === param.status) {
                        status = false;
                    }
                }
                this.headerFilter.status.map((item) => {
                    // 处理泡泡选中offer中，子状态都勾选
                    if(param.status == 7) {
                        if([7, 9,10,11,12,13].includes(item.code) && item.count >0) {
                            item.isSelect = true;
                        }
                    } else {
                        item.isSelect = item.code === param.status;
                    }
                });
                if (status) {
                    this.selectDataChange('status', [{
                        label: param.name,
                        code: param.status,
                        isSelect: true,
                    }]);
                }
            },
            bubbleClear(param) {
                this.selectData.status = [];
                for (let key in this.headerFilter.status) {
                    this.headerFilter.status[key].isSelect = false;
                }
                this.selectDataChange();
            },
            showPerformanceDialog(index, recommend) {
                this.currentIndex = index;
                this.$refs.recommendDialog.$refs.offerDialog.showHandle(recommend.id, null, '编辑业绩', true, !recommend.isPublisher, recommend, 'edit');
                // if(recommend.jobType == 3) {
                //     this.$refs.recommendDialog.$refs.offerDialog.showHandle(recommend.id, null, '编辑业绩', false, null, recommend);
                // } else {
                //     this.$refs.recommendDialog.$refs.offerDialog.showHandle(recommend.id, null, '编辑业绩', true, !recommend.isPublisher, recommend, 'edit');
                // }
            },
            //展示弹窗操作
            showRecommendDialog(index, nextStatus, recommend, btnText, recommendPostion) {
                this.$store.dispatch('setRecommendPosition', recommendPostion);

                this.activeIndex = index;
                this.$refs.recommendDialog.setStatus(recommend.status);
                switch (nextStatus) {
                    case 1:
                        //接受
                        this.$refs.recommendDialog.$refs.acceptDialog.showHandle(recommend.id, btnText, recommend.isShowMoreText || false);
                        break;
                    case 64:
                        //面试待确认
                        this.$refs.recommendDialog.setStatus(recommend.status, 64);
                        this.$refs.recommendDialog.$refs.stageDialog.showHandle(recommend.id, btnText);
                        break;
                    case 4:
                        //进入面试
                        this.$refs.recommendDialog.setStatus(recommend.status, 4);
                        this.$refs.recommendDialog.$refs.enterSureInterviewDialog.showHandle({
                            "btnText": btnText,
                            "id": recommend.id,
                            "realName": recommend.candidateName,
                            "jobName": recommend.job,
                            "headhunterRealName": recommend.jobOwnerRealName || '',
                            "headhunterNickname": recommend.jobOwnerNickName || '',
                            recommend: recommend,
                            nextStatus: 4,
                        });
                        break;

                        //进入面试
                        // this.$refs.recommendDialog.setStatus(recommend.status, 4); *
                        // this.$refs.recommendDialog.$refs.stageDialog.showHandle(recommend.id, btnText);
                        break;
                    case 2:
                        //拒绝
                        this.$refs.recommendDialog.$refs.rejectDialog.showHandle(recommend.id, btnText);
                        break;
                    case 5:
                         //安排面试
                         this.$refs.recommendDialog.$refs.interviewDialog.showHandle({
                            "btnText": btnText,
                            "id": recommend.id,
                            "realName": recommend.candidateName,
                            "jobName": recommend.job,
                            "headhunterRealName": recommend.jobOwnerRealName || '',
                            "headhunterNickname": recommend.jobOwnerNickName || ''
                        });
                        break;
                    case 407:
                        //面试反馈
                        this.$refs.recommendDialog.setStatus(recommend.status, 407);
                        this.$refs.recommendDialog.$refs.stageDialog.showHandle(recommend.id, btnText);
                        break;
                    case 6:
                        //s0
                        this.$refs.recommendDialog.$refs.processDialog.showHandle(recommend.interviewId, btnText);
                        break;
                    case 8:
                        // offer
                        // this.$refs.recommendDialog.$refs.offerDialog.showHandle(recommend.id, true, btnText);
                        this.$refs.recommendDialog.$refs.offerDialog.showHandle(recommend.id, true, btnText, false, null, recommend);
                        break;
                    case 9: case 10: case 11: case 12: case 13:
                        this.$confirm(`确认进入下一阶段？`, "提示", {
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            type: 'warning',
                            closeOnClickModal: false,
                        }).then(async () => {
                            this.tableDataLoading = true;
                            const res = await this.updateOfferingStage(recommend.id, nextStatus);
                            
                            this.updateList(null, nextStatus, res.status, res.interviewId || '', res.interviewConfirmedStatus);
                            this.tableDataLoading = false;
                            shortTips(`操作成功！`);
                        }).catch(() => {
                            shortTips(`取消操作！`);
                        })
                        break;
                    case 88:
                        // 一键offer
                        // this.$refs.recommendDialog.$refs.offerDialog.showHandle(recommend.id, false, btnText);
                        this.$refs.recommendDialog.$refs.offerDialog.showHandle(recommend.id, true, btnText, false, null, recommend);
                        break;
                    case 16:
                        //入职
                        this.$refs.recommendDialog.setStatus(recommend.status, 16);
                        this.$refs.recommendDialog.$refs.stageDialog.showHandle(recommend.id, btnText, '', recommend.status);
                        break;
                    case 32:
                        // 离职
                        this.$refs.recommendDialog.setStatus(recommend.status, 32);
                        this.$refs.recommendDialog.$refs.stageDialog.showHandle(recommend.id, btnText);
                        break;
                    case 1024:
                        myService.getLastStatus(recommend.id).then((res) => {
                            this.$refs.recommendDialog.setStatus(recommend.status, 1024);
                            this.$refs.recommendDialog.$refs.stageDialog.showHandle(recommend.id, btnText, res);
                        }).catch(err => {
                            shortTips(err.message || '获取权限失败');
                        });
                        // 已接受待推进面试状态淘汰候选人
                        break;
                    case 10241:
                        // 已接受待推进面试状态淘汰候选人
                        this.$refs.recommendDialog.$refs.obsoleteDialog.showHandle(recommend.id, 1, btnText);
                        break;
                    case 102440:
                        // 面试待安排状态淘汰候选人
                        this.$refs.recommendDialog.$refs.obsoleteDialog.showHandle(recommend.id, 40, btnText);
                        break;
                    case 10244:
                        // 面试已反馈状态淘汰候选人
                        this.$refs.recommendDialog.$refs.obsoleteDialog.showHandle(recommend.id, 4, btnText);
                        break;
                    case 10248:
                        // offer状态淘汰候选人
                        this.$refs.recommendDialog.$refs.obsoleteDialog.showHandle(recommend.id, 8, btnText);
                        break;
                    case 100:
                        //推荐至用户：姓名展开行中若该操作按钮消失，则该姓名所在的记录的推荐至客户按钮也要消失
                        this.tableData[index].isRecommendToCustomer = true;
                        break;
                }
            },
            updateOfferingStage(recommendationId, nextStatus, isCompleteOffering) {
                // if(this.recommendProcess.status == 13) return Promise.resolve(true);
                const params = {
                    isCompleteOffering: isCompleteOffering || false,
                    status: nextStatus,
                    recommendationId: recommendationId,
                };
                console.log(params)
                // return myService.updateOfferingStage(params);
                return myService.recommendOfferingStage(params);
            },
            editUpdateList(recruiteTime) {
                const TODAY = moment().format('YYYY-MM-DD');
                // console.log(TODAY)
                // console.log(recruiteTime)
                const _TODAY = new Date(TODAY).getTime();
                const _recruiteTime = new Date(recruiteTime).getTime();
                // console.log(_TODAY)
                // console.log(_recruiteTime)

                console.log(this.currentIndex)
                // 编辑业绩时，如果填写的入职时间超过今天则不显示开票按钮
                if(_TODAY >= _recruiteTime && this.tableData[this.currentIndex].status == 16) {
                    this.tableData[this.currentIndex].canInvoiceApplication = true;
                } else {
                    this.tableData[this.currentIndex].canInvoiceApplication = false;
                }
            },
            updateList(index, lastStatus, status, interviewId, interviewConfirmedStatus, recruiteTime) {
                const TODAY = moment().format('YYYY-MM-DD');
                const _TODAY = new Date(TODAY).getTime();
                const _recruiteTime = new Date(recruiteTime).getTime();

                if (index === null) {
                    index = this.activeIndex;
                }
                this.tableData[index].status = status;
                this.tableData[index].flowUpdateDate = moment(new Date()).format('YYYY-MM-DD');
                if(lastStatus === 64 && status === 4) {
                    this.tableData[index].interviewConfirmedStatus = 2;
                    // this.$nextTick(() => {
                    //     // stage(recommend, 5, '安排面试')
                    //     // showRecommendDialog
                    //     // 0 5 {} 安排面试 tableRow
                    //     this.showRecommendDialog(index, 5, this.tableData[index], '安排面试');
                    // });
                }
                // 入职操作后数据没有刷新
                if((lastStatus === 7 || lastStatus === 8 || lastStatus === 9 || lastStatus === 10 || lastStatus === 11 || lastStatus === 12 || lastStatus === 13) && (status === 16 && _TODAY >= _recruiteTime)) {
                    this.tableData[index].canInvoiceApplication = true;
                } else {
                    this.tableData[index].canInvoiceApplication = false;
                }

                if(status == 8) {
                    this.tableData[index].canCreateOfferInfo = false;
                }

                if(interviewConfirmedStatus != undefined) {
                    this.tableData[index].interviewConfirmedStatus = interviewConfirmedStatus;
                }
                if (interviewId) {
                    this.tableData[index].interviewId = interviewId;
                }
                for (let key in this.statusBubbleCount) {
                    if (this.statusBubbleCount[key].status === lastStatus) {
                        this.statusBubbleCount[key].count = this.statusBubbleCount[key].count - 1;
                    }
                    if (this.statusBubbleCount[key].status === status) {
                        this.statusBubbleCount[key].count = this.statusBubbleCount[key].count + 1;
                    }
                }
                this.headerFilter['status'].forEach((item) => {
                    if (item.code === lastStatus) {
                        item.count--;
                    }
                    if (item.code === status) {
                        item.count++;
                    }
                })
                this.selectDataChange();
                if(this.expandDataObj[this.tableData[index].id]) {
                    this.getExpandDataById(this.tableData[index].id);
                }
            },
            selectDataArrange(label, result) {
                //按照筛选顺序展示筛选列
                let obj = this.selectData;
                delete obj[label];
                obj[label] = result;
                this.selectData = obj;
            },
            initDefalutSelectSearch(){
                if(window.location.href.indexOf('?companyName') == -1){
                    return;
                }
                /* let jobName = decodeURI(decodeURI(_getQueryStringByHash('jobName')));
                let companyName = decodeURI(decodeURI(_getQueryStringByHash('companyName')));
                let statusType = _getQueryStringByHash('statusType'); */
                let jobName = _getQueryString('jobName');
                let companyName = _getQueryString('companyName');
                let statusType = _getQueryString('statusType');
                if(jobName){
                    this.defaultSelect.job = [
                        {
                            label: jobName
                        }
                    ];
                }
                if(companyName){
                    this.defaultSelect.customer = [
                        {
                            label: companyName
                        }
                    ]
                }
                //由于职位页状态逻辑与工作不一致，故先不做状态筛选
                /* if(statusType){
                    this.defaultSelect.status = this.selectStatusItems(statusType)
                } */
                for(let key in this.defaultSelect){
                    this.selectData[key] = this.defaultSelect[key];
                }
                this.selectDataChange();
            },
            selectStatusItems(type){
                //状态默认选项对应集合
                let statusArr = [];
                switch (type) {
                    case 'recommendation':
                        statusArr = [
                            {
                                label: '未处理'
                            },
                            {
                                label: '已接受待推进面试'
                            },
                            {
                                label: '推荐拒绝'
                            }
                        ]
                        break;
                    case 'accepted':
                        statusArr = [
                            {
                                label: '已接受待推进面试'
                            }
                        ]
                        break;
                    case 'rejected':
                        statusArr = [
                            {
                                label: '推荐拒绝'
                            }
                        ]
                        break;
                     case 'interview':
                        statusArr = [
                            {
                                label: '面试待安排'
                            },
                            {
                                label: '面试已安排'
                            },
                            {
                                label: '面试已反馈'
                            }
                        ]
                        break;
                     case 'offer':
                         statusArr = [
                             {
                                label: 'Offer'
                            }
                         ]
                         break;
                    default:
                        break;
                }

                return statusArr;
            },
            batchOperateSuccess() {
                this.isResetBubble = true;
                this.init();
            },
            defaultAction() {
                if (this.defaultSortStatus) {
                    this.defaultSortStatus = false;
                    //从工作台页面的系统消息提醒进入
                    let status = parseInt(this.$route.query.status);
                    this.$refs.bubble.handleBubble({
                        status: status,
                        name: status == 0 ? '未处理' : '已接受待推进面试',
                        count: 1
                    });
                }
            },
            handleTableTitle(val) {
                this.titleStore = [];
                this.titleStore = val;
                let tableName = this.filterIndex == 0 ? 'recommendToMe' : 'myRecommend';
                _tracker.track('TableTitleStore', JSON.stringify({
                    titleStore: val
                }));
                localStorage.setItem(`${tableName}TableTitleNew`, JSON.stringify(val));
            },
            handlePagerJump() {
                let currentPager = Number(this.pagerJump),
                currentPageTotal = Math.ceil(this.page.total/this.page.size);
                if(currentPager > 0 && currentPager <= currentPageTotal){
                    this.handleCurrentChange(currentPager);
                } else {
                    this.pagerJump = currentPageTotal;
                    this.handleCurrentChange(currentPageTotal);
                }
            },
            handleExpandChange(row, expandedRows) {
                if(expandedRows.indexOf(row) > -1) {
                    _tracker.track('Expand', JSON.stringify({
                        tableName: window._pageCode ? window._pageCode.replace('FloatingList', '') : '',
                        recommendId: row.id,
                        text: '姓名'
                    }));

                    //最多缓存50条数据
                    let expandDataKeysArr = Object.keys(this.expandDataObj);
                    if(expandDataKeysArr.length >= 50) {
                        let deleteKeysArr = expandDataKeysArr.slice(0, 10);
                        deleteKeysArr.forEach(key =>{
                            delete this.expandDataObj[key];
                        })
                    }

                    if(!this.expandDataObj[row.id]) {
                        this.getExpandDataById(row.id);
                    }
                }
            },
            getExpandDataById(id) {
                _request({
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    method: 'GET',
                    url: "/Headhunting/FloatingList/Recommendation/%p/Flows".replace(/\%p/ig, id)
                }).then((res) => {
                    this.$set(this.expandDataObj, id, res || []);
                });
            },
            getShareData(candidateInfo) {
                return {
                    resumeId: candidateInfo.candidateId,
                    candidateName: candidateInfo.candidateName,
                    title: candidateInfo.title,
                    company: candidateInfo.company,
                    cityName: candidateInfo.city,
                    yearOfExperience: candidateInfo.yearOfExperience,
                    degree: candidateInfo.degree,
                    school: candidateInfo.school
                }
            },
            
            // 再次操作确认进入面试列表相关代码
            showConfListDialog() {
                this.dispatch('App', 'show-confList-dialog');
            },
            // 再次操作确认进入面试列表相关代码 --end

            getUnapplyNumber (){
                floatingListService
                    .getPMUnapplyList({
                        Start: 0,
                        Take: 20,
                    })
                    .then((res) => {
                        // this.unApplyNumber = res.total;
                        this.unApplyNumber = res.total;
                    })
            },
        },
        watch: {
            orderPageinationTable() {
                this.page.total = this.orderPageinationTable.length;
                this.page.current = 0;
                this.pageination();
                this.getHeaderFilter();
            },
            searchTime() {
                this.init();
            },
            'this.$store.state.workbench.isActive': () => {
                this.resize();
            }
        }
    }
</script>

<style lang="scss" scoped>
    .hr-contact-card {
        padding: 10px;
        .hr-info {
            margin-bottom: 10px;
            display: flex;
            img {
                margin-right: 20px;
                width: 60px;
                height: 60px;
                border-radius: 50%;
            }
            .hr-base-info {
                .hr-name {
                    // font-size: 16px;
                    // color: #333333;
                    font-size: 14px;
                    color: #B9B9B9;
                    width: 130px;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }
                .hr-phone {
                    font-size: 16px;
                    color: #333333;
                }
            }
        }
        .hr-qr-code {
            width: 180px;
            height: 180px;
            // background-color: #36B495;
            margin: auto;
            img {
                width: 100%;
                height: 100%;
            }
        }
    }
    .color-orange {
        color: $orange;
    }

    .center {
        display: table;
        margin: auto;
    }

    .text-ellipsis {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .icon-market{
        position: absolute;
        display: inline-block;
        left: -31px;
        top: -8px;
        background-color: #ff6564;
        border-radius: 4px;
        padding: 3px;
        font-size: 22px;
        color: #fff;
        transform: scale(0.5);

        &:before{
            content: '市场';
            display: inline;
        }
    }
    .await-confirm {
        position: absolute;
        display: inline-block;
        left: 25px;
        top: -8px;
        background-color: #FEF6E8;
        border-radius: 4px;
        padding: 3px;
        font-size: 22px;
        color: #F5A623;
        transform: scale(0.5);

        &:before{
            content: '待确认';
            display: inline;
        }
    }

    /*
        .bg-gray{

            box-shadow: 4px 0 4px -2px #CCCCCC;
        }
    */

    .select-container {
        // margin-top: 16px;
        // margin-bottom: 6px;
        line-height: 44px;
        position: relative;
        // padding-right: 40px;

        background: white;
        border-radius: 4px;
        margin: 6px 0;
        padding: 0 16px;

        &-point-tip {
            margin-left: 10px;
            font-size: 14px;
            color: #EE6E4F;
            line-height: 28px;
            float: left;
        }

        > span:first-child {
            float: left;
            line-height: 44px;
        }

        .select-tag-list {
            display: inline-block;
            padding-top: 9px;
            margin-left: 10px;
            height: 44px;
            vertical-align: top;

            .select-tag {
                display: inline-block;
                position: relative;
                margin-right: 10px;
                padding: 0 28px 0 8px;
                max-width: 486px;
                height: 28px;
                line-height: 28px;
                color: $primary;
                background: white;
                border: 1px solid $primary;
                border-radius: 4px;

                &:last-child {
                    margin-right: 0;
                }

                .btn-close {
                    float: right;
                    position: absolute;
                    right: 10px;
                    top: 0;

                    &:after {

                        display: inline-block;
                        content: '+';
                        float: right;
                        width: 14px;
                        height: 28px;
                        transform: rotate(-45deg);
                        font-size: 20px;

                        text-align: center;
                        line-height: 26px;
                        cursor: pointer;
                        color: $primary;
                    }

                    &:hover {
                        color: $primary;
                    }
                }
            }
        }
        // 再次操作确认进入面试列表相关代码
        .show-confirm-list {
            position: absolute;
            top: 0;
            right: 40px;
        }
        .show-confirm-list-btn {
            background-color: #fff;
            border-color: #ccc;
            color: #666;
            position: relative;
            z-index: 1000;
            &:hover {
                background-color: #48CAAC;
                border-color: #48CAAC;
                color: #fff;
            }
        }
        .show-confirm-list-guide {
            position: absolute;
            bottom: 40px;
            left: -72px;
            z-index: 1000;
            width: 254px;
            height: 64px;
            border-radius: 6px;
            background-color: #fff;
            color: #333;
            font-size: 16px;
            text-align: center;
            line-height: 64px;
            &::before {
                content: "";
                position: absolute;
                bottom: -10px;
                left: 136px;
                width: 10px;
                height: 10px;
                background-color: #fff;
                transform: rotate(-45deg);
                transform-origin: 0 0;
            }
            .hide-guide-btn {
                position: absolute;
                right: 6px;
                top: 6px;
                font-size: 14px;
                font-weight: bold;
                color: #999;
                cursor: pointer;
                &:hover {
                    color: #333;
                }
            }
        }
        .show-confirm-list-modal {
            position: fixed;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            z-index: 999;
            background-color: rgba(0, 0, 0, 0.4);
        }
        // 再次操作确认进入面试列表相关代码 --end
        .floating-list-table-title{
            position: absolute;
            top: 2px;
            right: 16px;
        }
    }

.work-table-container {
    height: 100%;
    overflow-x: hidden;
    .work-table /deep/ {
        height: 100%;
        min-height: 500px;
        padding: 10px 20px 0 20px;
        background: url('~@src/assets/images/home/home_bg.png') bottom center no-repeat;
        display: flex;
        flex-direction: column;

        .el-table {
            height: 60.6% !important;
            min-height: 244px;
            overflow-y: auto;

            .el-loading-mask {
                z-index: 900;
            }

            th {
                padding: 0;
                height: 42px;
                line-height: 42px;
                background-color: #E8E8E8;
                border-color: #CCCCCC;
                color: #666666;

                .el-checkbox {
                    padding: 0 10px;
                }

                .cell {
                    padding: 0;

                    .caret-wrapper {
                        display: none;
                    }

                    .el-dropdown {
                        float: left;
                        width: 100%;
                        padding: 0;
                        text-align: center;
                        &.time-sort-dropdown {
                            line-height: unset;
                            margin-top: -7px;
                        }
                    }

                    .el-checkbox {
                        display: none;
                    }
                }

                &.name-cell .table-slot {
                    text-indent: 0;
                }
            }

            td {
                padding: 0;
                height: 40px;
                line-height: 40px;

                .cell {
                    white-space: nowrap;

                    .el-checkbox__input.is-checked .el-checkbox__inner {
                        background-color: #fff;
                        border-color: $primary;

                        &::after {
                            border-color: $primary;
                        }
                    }

                    .job-link {
                        color: #666;

                        &:hover {
                            color: $primary;
                        }
                    }
                }

                .table-avatar {
                    display: block;
                    width: 24px;
                    height: 24px;
                    border-radius: 50%;
                }

                &.btn-menu .cell {
                    padding: 0;
                }

                &.avatar-cell .table-slot {
                    text-indent: 0;
                }

                .recommend-avatar {
                    position: relative;
                    .hr-img {
                        width: 24px;
                        height: 24px;
                        border-radius: 50%;
                    }
                }
            }

            .el-table__row {

                .el-checkbox__inner {
                    border-color: #999;
                }

                &.lazy {

                    td {
                        color: #BBBBBB;

                        &.name-cell .cell .candidate-link {
                            color: #bbb;

                            &:hover {
                                color: $primary;
                            }
                        }
                        &.expand-cell {
                            .el-table__expand-icon {
                                color: #bbb;
                            }

                            .el-table__expand-icon--expanded {
                                color: $primary;
                            }
                        }

                    }

                    .el-checkbox__inner {
                        border-color: #BBBBBB;
                    }

                }
            }

            .table-slot {
                padding: 0;
                text-indent: 10px;
                line-height: unset;
            }

            .table-slot-content {
                text-indent: 0;
            }

            .is-center .table-slot {
                text-indent: 0;
            }

            .time-sort-cell .cell {
                line-height: 40px;
            };

            .expand-cell {
                border-right: none;

                .el-table__expand-icon--expanded {
                    color: $primary;
                }

                .el-table__expand-icon > .el-icon {
                    z-index: 10;

                    &.el-icon-arrow-right {
                        font-weight: bold;
                    }
                }
            }

            .name-cell .cell {
                padding-left: 5px;

                .candidate-link {
                    color: #666;

                    .hr-tag {
                        display: inline-block;
                        width: 26px;
                        height: 16px;
                        line-height: 16px;
                        background: #39f;
                        border-radius: 4px;
                        font-size: 11px;
                        color: #FFFFFF;
                        text-align: center;
                    }
                    .operation-tag {
                        display: inline-block;
                        width: 37px;
                        height: 16px;
                        line-height: 16px;
                        background: #39f;
                        border-radius: 4px;
                        font-size: 11px;
                        color: #FFFFFF;
                        text-align: center;
                    }

                    &:hover {
                        color: $primary;
                    }
                }
            }

            .bg-green-dark {
                background-color: #ebf8f5;
            }

            .bg-orange-dark {
                background-color: #ffe4ce;
            }

            .el-table__empty-block {
                .el-table__empty-text {
                    line-height: inherit;
                }

                .empty-img {
                    display: inline-block;
                    background: url('~@src/assets/images/home/icon_no_data.svg') no-repeat;
                    width: 240px;
                    height: 228px;
                }

                .empty-data {
                    font-size: 16px;
                    line-height: 21px;
                    margin-bottom: 0px;
                }
            }

            .el-table-column--selection {
                border-right: none;
            }

            .recommend-dynamic {
                position: relative;
                z-index: 5;
            }
        }

        .el-table__fixed {
            .recommend-dynamic {
                visibility: hidden;
            }
        }
        .work-table-footer .footer-left .work-table-btn {
            display: inline-block;
            height: 28px;
            width: 84px;
            margin-right: 10px;
            border-radius: 4px;
            color: #fff;
            border: none;
            outline: none;

            &.work-table-btn-orange {
                background-color: #FC7859;

                &:hover {
                    background-color: #EE6E4F;
                }
            }

            &.work-table-btn-primary {
                background-color: $primary;

                &:hover {
                    background-color: #36B495;
                }
            }

            &[disabled] {
                background-color: #F8F8F8;
                border: 1px solid #DDDDDD;
                color: #999999;
                cursor: not-allowed;

                &:hover {
                    background-color: #F8F8F8;
                }
            }
        }
    }
}
</style>

<style lang="scss">
    @import "#/css/scss/variables.scss";
    .recommend-time-dropdown {
        &.el-dropdown-menu.el-popper {
            width: 124px;
            margin: 0;
            padding: 0;
            text-align: center;
            top: 220px;
        }

        .el-dropdown-menu__item {
            color: #666;
            padding: 0;
        }

        .popper__arrow {
            display: none;
        }

        .checked {
            color: $primary;
            background-color: rgba($primary, .1);
        }
    }

    .header-name-popover{
        margin-left: -23px;
    }

    .el-tooltip__popper {
        max-width: 300px;
    }

    .label-tip.el-tooltip__popper.is-light {
        margin-left: -8px;
        max-width: 480px;
        line-height: 20px;
        border: none;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);

        .popper__arrow {
            border: none;
        }
    }
    .filter-wrap{
        padding: 10px 20px;
        background-color: #FFFFFF;
        border-radius: 4px;
        .filter-area{
            border-top: 1px dashed #CCC;
            box-sizing: border-box;
            padding-top: 10px;
            margin-top: 10px;
            .filter-left-text{
                display: inline-block;
                font-size: 14px;
                font-weight: 400;
                color: #999999;
            }
            .filter-cell{
                display: inline-block;
                margin-right: 20px;
            }
            .hot-light{
                color: #38BC9D;
            }
            &.no-top-border{
                border-top: 1px dashed transparent;
            }
        } 
    }
    .el-table{
        .el-table__body-wrapper{
            overflow-y: auto!important;;
        }
    }
    
</style>

