<template>
    <el-dialog
        class="floating-list-accept-dialog"
        title=""
        :visible.sync="dialogVisible"
        width="608px"
        :show-close="false"
        :before-close="handleClose"
    >
        <div class="accept-modal-content">
            <p class="content-tip">
                确定批量接受 <span class="name-color">{{names}}</span> 等 <span class="warning-color">{{total}}</span> 名候选人？
                <br>
                <span v-if="!!outSideNames" class="color-red">其中【{{ outSideNames }}】为非公司内部人员推荐，我们企业库也有此人才。</span>
            </p>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="handleClose">取消</el-button>
            <el-button type="primary" @click="handleConfirm" :loading="confirmLoading">确定</el-button>
        </span>
    </el-dialog>
</template>
<script>
import server from '#/js/service/floatingListService.js'
export default {
    components:{
    },
    data(){
        return{
            dialogVisible: false,
            confirmLoading: false,
            ids:[],
            total:0,
            names:'',
            outSideNames: '',
        }
    },
    props: {
        tableName: String
    },
    computed:{
    },
    mounted(){
    },
    methods:{
        show(data, outSideNames =[]){
            this.total = data.length;
            this.outSideNames = outSideNames.join('、');
            let nameArray = [];
            let idArray = [];
            data.forEach((item) => {
                nameArray.push(item.candidateName);
                idArray.push(item.id);
            });
            this.names = nameArray.splice(0,8).join('、');
            this.ids = idArray;
            this.dialogVisible = true;
        },
        handleClose(){
            this.dialogVisible = false;
        },
        handleConfirm(){
            if(this.confirmLoading) {
                return false;
            }

            _tracker.track("BatchAccept", JSON.stringify({
                "tableName": this.tableName,
                "text": "接受推荐"
            }))

            this.confirmLoading = true;
            let ids = this.ids;
            server.handleBatchAccept({RecommendationIds:ids}).then((res)=>{
                this.$emit('accept-success')
                shortTips('操作成功!');
                this.dialogVisible = false;
            }).finally(()=>{
                this.confirmLoading = false;
            })
        }
    }
}
</script>
<style lang="scss">
    .floating-list-accept-dialog {
        .el-dialog__header {
            display: none;
        }
    }
    .accept-modal-content{
        margin: 50px 60px 0;
        
        .color-red{
            color: red;
        }
        .content-tip{
            font-size: 16px;
            line-height: 24px;
            color: #2a2a2a;
            margin-bottom: 56px;
        }
        .warning-color{
            color: #FC7859;
        }
        .name-color{
            color: #38BC9C;
        }
        .content-footer{
            text-align: center;
            .accept-modal-btn{
                width: 100px;
                height: 36px;
                font-size: 14px;
                border-radius: 2px;
                border: none;
                outline: none;
                cursor: pointer;
                &-cancel{
                    color: #999999;
                    background-color: #EEEEEE;
                    &:hover{
                        background-color: #DDDDDD;
                    }
                }
                &-confirm{
                    color: #FFFFFF;
                    background: #38BC9D;;
                    margin-left: 50px;
                    &:hover{
                        background-color: #36B495;
                    }
                }
            }
            
        }
    }
</style>
