var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "recommend-dynamic",
    },
    [
      _vm.listData && _vm.listData.length > 0
        ? _vm._l(_vm.listData, function (recommend, index) {
            return _c(
              "div",
              { key: index, staticClass: "recommend-dynamic-item" },
              [
                recommend.type == 1
                  ? [
                      _c("div", { staticClass: "recommend-dynamic-text" }, [
                        _c("span", { staticClass: "text-green" }, [
                          _vm._v(_vm._s(recommend.operator)),
                        ]),
                        _vm._v("\n                    推荐候选人至【"),
                        _c("span", { staticClass: "text-green" }, [
                          _vm._v(
                            _vm._s(recommend.customer) +
                              " \n                        "
                          ),
                          _c(
                            "a",
                            {
                              attrs: {
                                target: "_blank",
                                href: "/#/Job/" + recommend.jobId,
                              },
                            },
                            [_vm._v(_vm._s(recommend.job))]
                          ),
                        ]),
                        _vm._v("】\n                "),
                      ]),
                      _c("div", { staticClass: "recommend-dynamic-time" }, [
                        _vm._v(_vm._s(recommend.operationDate)),
                      ]),
                    ]
                  : _vm._e(),
                recommend.type == 2
                  ? [
                      _c("div", { staticClass: "recommend-dynamic-text" }, [
                        _c("span", { staticClass: "text-green" }, [
                          _vm._v(_vm._s(recommend.operator)),
                        ]),
                        _vm._v(
                          "\n                    接受了候选人\n                "
                        ),
                      ]),
                      _c("div", { staticClass: "recommend-dynamic-time" }, [
                        _vm._v(_vm._s(recommend.operationDate)),
                      ]),
                    ]
                  : _vm._e(),
                recommend.type == 28
                  ? [
                      _c("div", { staticClass: "recommend-dynamic-text" }, [
                        _c("span", { staticClass: "text-green" }, [
                          _vm._v(_vm._s(recommend.operator)),
                        ]),
                        _vm._v(
                          "\n                    将候选人推进到面试阶段，等待\n                    "
                        ),
                        _c("span", { staticClass: "text-green" }, [
                          _vm._v(_vm._s(recommend.unconfirmedUserName)),
                        ]),
                        _vm._v("\n                    确认\n                "),
                      ]),
                      _c("div", { staticClass: "recommend-dynamic-time" }, [
                        _vm._v(_vm._s(recommend.operationDate)),
                      ]),
                    ]
                  : _vm._e(),
                recommend.type == 5
                  ? [
                      _c("div", { staticClass: "recommend-dynamic-text" }, [
                        _c("span", { staticClass: "text-green" }, [
                          _vm._v(_vm._s(recommend.operator)),
                        ]),
                        _vm._v(
                          "\n                    将候选人推进到面试阶段\n                "
                        ),
                      ]),
                      _c("div", { staticClass: "recommend-dynamic-time" }, [
                        _vm._v(_vm._s(recommend.operationDate)),
                      ]),
                    ]
                  : _vm._e(),
                recommend.type == 3
                  ? [
                      _c("div", { staticClass: "recommend-dynamic-text" }, [
                        _c("span", { staticClass: "text-green" }, [
                          _vm._v(_vm._s(recommend.operator)),
                        ]),
                        _vm._v(
                          "\n                    拒绝了候选人\n                "
                        ),
                      ]),
                      _c("div", { staticClass: "recommend-dynamic-time" }, [
                        _vm._v(_vm._s(recommend.operationDate)),
                      ]),
                    ]
                  : _vm._e(),
                recommend.type == 4
                  ? [
                      _c("div", { staticClass: "recommend-dynamic-text" }, [
                        _c("span", { staticClass: "text-green" }, [
                          _vm._v(_vm._s(recommend.operator)),
                        ]),
                        _vm._v(
                          "\n                    重新接受了候选人\n                "
                        ),
                      ]),
                      _c("div", { staticClass: "recommend-dynamic-time" }, [
                        _vm._v(_vm._s(recommend.operationDate)),
                      ]),
                    ]
                  : _vm._e(),
                recommend.type == 6
                  ? [
                      _c("div", { staticClass: "recommend-dynamic-text" }, [
                        _c("span", { staticClass: "text-green" }, [
                          _vm._v(_vm._s(recommend.operator)),
                        ]),
                        _vm._v(
                          "\n                    为候选人安排了面试\n                "
                        ),
                      ]),
                      _c("div", { staticClass: "recommend-dynamic-time" }, [
                        _vm._v(_vm._s(recommend.operationDate)),
                      ]),
                    ]
                  : _vm._e(),
                recommend.type == 8
                  ? [
                      _c("div", { staticClass: "recommend-dynamic-text" }, [
                        _c("span", { staticClass: "text-green" }, [
                          _vm._v(_vm._s(recommend.operator)),
                        ]),
                        _vm._v(
                          "\n                    反馈了候选人的面试结果（" +
                            _vm._s(recommend.interviewResult) +
                            "）\n                "
                        ),
                      ]),
                      _c("div", { staticClass: "recommend-dynamic-time" }, [
                        _vm._v(_vm._s(recommend.operationDate)),
                      ]),
                    ]
                  : _vm._e(),
                recommend.type == 9
                  ? [
                      _c("div", { staticClass: "recommend-dynamic-text" }, [
                        _c("span", { staticClass: "text-green" }, [
                          _vm._v(_vm._s(recommend.operator)),
                        ]),
                        _vm._v(
                          "\n                    候选人推送到已Offer阶段\n                "
                        ),
                      ]),
                      _c("div", { staticClass: "recommend-dynamic-time" }, [
                        _vm._v(_vm._s(recommend.operationDate)),
                      ]),
                    ]
                  : _vm._e(),
                recommend.type == 10
                  ? [
                      _c("div", { staticClass: "recommend-dynamic-text" }, [
                        _c("span", { staticClass: "text-green" }, [
                          _vm._v(_vm._s(recommend.operator)),
                        ]),
                        _vm._v(
                          "\n                    将候选人推送到入职阶段\n                "
                        ),
                      ]),
                      _c("div", { staticClass: "recommend-dynamic-time" }, [
                        _vm._v(_vm._s(recommend.operationDate)),
                      ]),
                    ]
                  : _vm._e(),
                recommend.type == 17
                  ? [
                      _c("div", { staticClass: "recommend-dynamic-text" }, [
                        _c("span", { staticClass: "text-green" }, [
                          _vm._v(_vm._s(recommend.operator)),
                        ]),
                        _vm._v(
                          "\n                    将候选人设置为离职状态\n                "
                        ),
                      ]),
                      _c("div", { staticClass: "recommend-dynamic-time" }, [
                        _vm._v(_vm._s(recommend.operationDate)),
                      ]),
                    ]
                  : _vm._e(),
                recommend.type == 11
                  ? [
                      _c("div", { staticClass: "recommend-dynamic-text" }, [
                        _c("span", { staticClass: "text-green" }, [
                          _vm._v(_vm._s(recommend.operator)),
                        ]),
                        _vm._v(
                          "\n                    淘汰了候选人\n                "
                        ),
                      ]),
                      _c("div", { staticClass: "recommend-dynamic-time" }, [
                        _vm._v(_vm._s(recommend.operationDate)),
                      ]),
                    ]
                  : _vm._e(),
                recommend.type == 26
                  ? [
                      _c("div", { staticClass: "recommend-dynamic-text" }, [
                        _c("span", { staticClass: "text-green" }, [
                          _vm._v(_vm._s(recommend.operator)),
                        ]),
                        _vm._v(
                          "\n                    恢复了候选人\n                "
                        ),
                      ]),
                      _c("div", { staticClass: "recommend-dynamic-time" }, [
                        _vm._v(_vm._s(recommend.operationDate)),
                      ]),
                    ]
                  : _vm._e(),
                [41, 29, 30, 31, 32, 33].includes(recommend.type)
                  ? [
                      _c("div", { staticClass: "recommend-dynamic-text" }, [
                        _c("span", { staticClass: "text-green" }, [
                          _vm._v(_vm._s(recommend.operator)),
                        ]),
                        _vm._v(
                          "\n                    候选人进入" +
                            _vm._s(_vm.offerStatusMap[recommend.type]) +
                            "阶段\n                "
                        ),
                      ]),
                      _c("div", { staticClass: "recommend-dynamic-time" }, [
                        _vm._v(_vm._s(recommend.operationDate)),
                      ]),
                    ]
                  : _vm._e(),
                [35, 37].includes(recommend.type)
                  ? [
                      _c("div", { staticClass: "recommend-dynamic-text" }, [
                        _c("span", { staticClass: "text-green" }, [
                          _vm._v(_vm._s(recommend.operator)),
                        ]),
                        _c("span", {
                          domProps: {
                            innerHTML: _vm._s(_vm.genereteHtml(recommend)),
                          },
                        }),
                      ]),
                      _c("div", { staticClass: "recommend-dynamic-time" }, [
                        _vm._v(_vm._s(recommend.operationDate)),
                      ]),
                    ]
                  : _vm._e(),
                _vm.recommendation.canOperate && index == 0
                  ? _c("recommend-operation", {
                      attrs: {
                        recommend: _vm.recommendation,
                        isRecommendList: true,
                        "recommend-position": _vm.recommendPosition,
                      },
                      on: {
                        "show-performance-dialog": _vm.showPerformanceDialog,
                        "show-dialog": _vm.showDialog,
                      },
                    })
                  : _vm._e(),
              ],
              2
            )
          })
        : _vm._e(),
      !_vm.listData
        ? _c("div", { staticClass: "recommend-dynamic-item" }, [
            _vm._v("正在加载"),
          ])
        : _vm._e(),
      _vm.listData && _vm.listData.length == 0
        ? _c("div", { staticClass: "recommend-dynamic-item" }, [
            _vm._v("暂无数据"),
          ])
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }