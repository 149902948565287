var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "floating-list-accept-dialog",
      attrs: {
        title: "",
        visible: _vm.dialogVisible,
        width: "608px",
        "show-close": false,
        "before-close": _vm.handleClose,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c("div", { staticClass: "accept-modal-content" }, [
        _c("p", { staticClass: "content-tip" }, [
          _vm._v("\n            确定批量接受 "),
          _c("span", { staticClass: "name-color" }, [
            _vm._v(_vm._s(_vm.names)),
          ]),
          _vm._v(" 等 "),
          _c("span", { staticClass: "warning-color" }, [
            _vm._v(_vm._s(_vm.total)),
          ]),
          _vm._v(" 名候选人？\n            "),
          _c("br"),
          !!_vm.outSideNames
            ? _c("span", { staticClass: "color-red" }, [
                _vm._v(
                  "其中【" +
                    _vm._s(_vm.outSideNames) +
                    "】为非公司内部人员推荐，我们企业库也有此人才。"
                ),
              ])
            : _vm._e(),
        ]),
      ]),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.handleClose } }, [_vm._v("取消")]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.confirmLoading },
              on: { click: _vm.handleConfirm },
            },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }