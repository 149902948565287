<template>
    <el-dialog
        class="floating-list-remove-dialog"
        title=""
        :visible.sync="dialogVisible"
        width="608px"
        :show-close="false"
        :before-close="handleClose"
    >
        <div class="remove-modal-content">
            <div class="content-title">
                <p>当前已选 <span class="warning-color">{{total}}</span> 位候选人</p>
                <p>移除Floating List后，无法撤销，请谨慎操作</p>
            </div>
            <p class="content-tip"><span class="warning-color">注意：</span>移除Floating List的数据，当候选人不在Tracking List，将自动添加至Tracking List。候选人在Tracking List时，仅变更推荐记录状态：推荐流程->推荐拒绝；面试流程->淘汰；入职->离职。该操作不影响下次推荐。</p>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="handleClose">取消</el-button>
            <el-button type="primary" @click="handleConfirm" :loading="confirmLoading">确定</el-button>
        </span>
    </el-dialog>
</template>
<script>
import server from '#/js/service/floatingListService.js'
export default {
    components:{
    },
    data(){
        return{
            dialogVisible: false,
            confirmLoading: false,
            ids: [],
            total: 0,
        }
    },
    props: {
        tableName: String,
        type: {
            type: Number,
            default: 0
        }
    },
    computed:{
    },
    mounted(){
    },
    methods:{
        show(data){
            this.total = data.length;
            let idArray = [];
            data.forEach((item) => {
                idArray.push(item.id);
            });
            this.ids = idArray;
            this.dialogVisible = true;
        },
        handleClose(){
            this.dialogVisible = false;
        },
        handleConfirm(){
            if(this.confirmLoading) {
                return false;
            }

            _tracker.track("BatchRemove", JSON.stringify({
                "tableName": this.tableName,
                "text": "移除"
            }))

            this.confirmLoading = true;
            let ids = this.ids;
            let type = this.type;
            server.handleBatchRemove({RecommendationIds:ids, type: type}).then((res)=>{
                this.$emit('remove-success')
                shortTips('操作成功!');
                this.dialogVisible = false;
            }).finally(()=>{
                this.confirmLoading = false;
            })
        }
    }
}
</script>
<style lang="scss">
    .floating-list-remove-dialog {
        .el-dialog__header {
            display: none;
        }
        .remove-modal-content{
            margin: 50px 60px 0;
            
            .content-title{
                font-size: 16px;
                color: #444444;
                letter-spacing: 0;
                width: 317px;
                text-align: center;
                margin: 0 auto 14px;
                p{
                    line-height: 24px;
                    display: inline-block;
                }
            }
            .content-tip{
                font-size: 12px;
                line-height: 20px;
                color: #2a2a2a;
                margin-bottom: 30px;
            }
            .warning-color{
                color: #FC7859;
            }
            .content-footer{
                text-align: center;
                .remove-modal-btn{
                    width: 100px;
                    height: 36px;
                    border-radius: 2px;
                    font-size: 14px;
                    border: none;
                    outline: none;
                    cursor: pointer;
                    &-cancel{
                        color: #999999;
                        background-color: #EEEEEE;
                        &:hover{
                            background-color: #DDDDDD;
                        }
                    }
                    &-confirm{
                        color: #FFFFFF;
                        background: #38BC9D;;
                        margin-left: 50px;
                        &:hover{
                            background-color: #36B495;
                        }
                    }
                }
                
            }
        }
    }
</style>
