var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "floating-list-todo-modal",
      attrs: { title: _vm.modalTitle, visible: _vm.dialogVisible },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c("div", { staticClass: "content-date" }, [
        _c("span", { staticClass: "date" }, [
          _vm._v("-" + _vm._s(_vm.currentDate) + "-"),
        ]),
      ]),
      _vm.untreated && _vm.untreatedCount > 0
        ? _c("div", { staticClass: "content-list" }, [
            _c("div", { staticClass: "list-title" }, [
              _vm._v("截止到今天 "),
              _c("span", { staticClass: "number-color" }, [
                _vm._v(_vm._s(_vm.currentTime)),
              ]),
              _vm._v(" ，您一共有 "),
              _c("span", { staticClass: "number-color" }, [
                _vm._v(_vm._s(_vm.untreated.totalStatisticsCount)),
              ]),
              _vm._v(" 个新收到的候选人未处理，其中："),
            ]),
            _c(
              "ul",
              _vm._l(_vm.untreated.reminderItem, function (item, index) {
                return item.statisticsCount > 0
                  ? _c("li", { staticClass: "list-item" }, [
                      _c("i", { staticClass: "circle" }),
                      _vm._v("有 "),
                      _c("span", { staticClass: "number-color" }, [
                        _vm._v(_vm._s(item.statisticsCount)),
                      ]),
                      item.message == "7天内"
                        ? _c("span", [_vm._v(" 个新候选人")])
                        : _vm._e(),
                      item.message != "7天内"
                        ? _c("span", [_vm._v(" 个候选人")])
                        : _vm._e(),
                      _c("span", { staticClass: "date-color" }, [
                        _vm._v(_vm._s(item.message)),
                      ]),
                      item.message == "7天内"
                        ? _c("span", [_vm._v("收到且未处理")])
                        : _vm._e(),
                      item.message == "7-30天"
                        ? _c("span", [_vm._v("未处理")])
                        : _vm._e(),
                      item.message == "30天以上"
                        ? _c("span", [_vm._v("未处理的")])
                        : _vm._e(),
                    ])
                  : _vm._e()
              }),
              0
            ),
          ])
        : _vm._e(),
      _vm.accepted && _vm.acceptedCount > 0
        ? _c("div", { staticClass: "content-list" }, [
            _c("div", { staticClass: "list-title" }, [
              _vm._v("截止到今天 "),
              _c("span", { staticClass: "number-color" }, [
                _vm._v(_vm._s(_vm.currentTime)),
              ]),
              _vm._v("，您一共有 "),
              _c("span", { staticClass: "number-color" }, [
                _vm._v(_vm._s(_vm.accepted.totalStatisticsCount)),
              ]),
              _vm._v(" 个候选人推荐接受，其中："),
            ]),
            _c(
              "ul",
              _vm._l(_vm.accepted.reminderItem, function (item, index) {
                return item.statisticsCount > 0
                  ? _c("li", { staticClass: "list-item" }, [
                      _c("i", { staticClass: "circle" }),
                      _vm._v("有 "),
                      _c("span", { staticClass: "number-color" }, [
                        _vm._v(_vm._s(item.statisticsCount)),
                      ]),
                      _vm._v(" 个候选人推荐接受后"),
                      _c("span", { staticClass: "date-color" }, [
                        _vm._v(_vm._s(item.message)),
                      ]),
                      _vm._v("状态未变更\n                "),
                    ])
                  : _vm._e()
              }),
              0
            ),
          ])
        : _vm._e(),
      _vm.removealbe && _vm.removealbeCount > 0
        ? _c("div", { staticClass: "content-list" }, [
            _c("div", { staticClass: "list-title" }, [
              _vm._v("截止到今天 "),
              _c("span", { staticClass: "number-color" }, [
                _vm._v(_vm._s(_vm.currentTime)),
              ]),
              _vm._v("，系统认为您一共有 "),
              _c("span", { staticClass: "number-color" }, [
                _vm._v(_vm._s(_vm.removealbe.totalStatisticsCount)),
              ]),
              _vm._v(" 个候选人可以移除，其中："),
            ]),
            _c(
              "ul",
              _vm._l(_vm.removealbe.reminderItem, function (item) {
                return item.statisticsCount > 0
                  ? _c("li", { staticClass: "list-item" }, [
                      _c("i", { staticClass: "circle" }),
                      _vm._v("有 "),
                      _c("span", { staticClass: "number-color" }, [
                        _vm._v(_vm._s(item.statisticsCount)),
                      ]),
                      item.reminderType == 3 || item.reminderType == "3"
                        ? _c("span", [_vm._v(" 个候选人推荐接受后")])
                        : _vm._e(),
                      item.reminderType == 2 || item.reminderType == "2"
                        ? _c("span", [_vm._v(" 个候选人推荐后")])
                        : _vm._e(),
                      item.reminderType == 1 || item.reminderType == "1"
                        ? _c("span", [_vm._v(" 个候选人收到后")])
                        : _vm._e(),
                      _c("span", { staticClass: "date-color" }, [
                        _vm._v(_vm._s(item.message)),
                      ]),
                      item.reminderType == 3 || item.reminderType == "3"
                        ? _c("span", [_vm._v("状态未变更")])
                        : _vm._e(),
                      item.reminderType == 2 || item.reminderType == "2"
                        ? _c("span", [_vm._v("仍未被处理")])
                        : _vm._e(),
                      item.reminderType == 1 || item.reminderType == "1"
                        ? _c("span", [_vm._v("仍未处理")])
                        : _vm._e(),
                    ])
                  : _vm._e()
              }),
              0
            ),
          ])
        : _vm._e(),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "button",
            { staticClass: "content-btn", on: { click: _vm.handleClose } },
            [_vm._v("~知道啦~")]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }