export default {
    data:{
        layerId: ''
    },
    methods: {
        layerShow(showHandler) {
            this.layerId = _layerRegister.generateId();
            _layerRegister.add({
                id: this.layerId,
                show: showHandler
            })
        },
        layerHide() {
            _layerRegister.delete(this.layerId);
        }
    }
}