<template>
    <el-dialog
        :title="modalTitle"
        class="floating-list-todo-modal"
        :visible.sync="dialogVisible"
        >
            <div class="content-date">
                <span class="date">-{{currentDate}}-</span>
            </div>
            <div class="content-list" v-if="untreated&&untreatedCount>0">
                <div class="list-title">截止到今天 <span class="number-color">{{currentTime}}</span> ，您一共有 <span class="number-color">{{untreated.totalStatisticsCount}}</span> 个新收到的候选人未处理，其中：</div>
                <ul>
                    <li class="list-item" v-for="(item,index) in untreated.reminderItem"   v-if="item.statisticsCount>0">
                        <i class="circle"></i>有 <span class="number-color">{{item.statisticsCount}}</span>
                        <span v-if="item.message=='7天内'"> 个新候选人</span>
                        <span v-if="item.message!='7天内'"> 个候选人</span>
                        <span class="date-color">{{item.message}}</span>
                        <span v-if="item.message=='7天内'">收到且未处理</span>
                        <span v-if="item.message=='7-30天'">未处理</span>
                        <span v-if="item.message=='30天以上'">未处理的</span>
                    </li>
                </ul>
            </div>
            <div class="content-list" v-if="accepted&&acceptedCount>0">
                <div class="list-title">截止到今天 <span class="number-color">{{currentTime}}</span>，您一共有 <span class="number-color">{{accepted.totalStatisticsCount}}</span> 个候选人推荐接受，其中：</div>
                <ul>
                    <li class="list-item" v-for="(item,index) in accepted.reminderItem"  v-if="item.statisticsCount>0">
                        <i class="circle"></i>有 <span class="number-color">{{item.statisticsCount}}</span> 个候选人推荐接受后<span class="date-color">{{item.message}}</span>状态未变更
                    </li>
                </ul>
            </div>
            <div class="content-list"  v-if="removealbe&&removealbeCount>0">
                <div class="list-title">截止到今天 <span class="number-color">{{currentTime}}</span>，系统认为您一共有 <span class="number-color">{{removealbe.totalStatisticsCount}}</span> 个候选人可以移除，其中：</div>
                <ul>
                    <li class="list-item" v-for="item in removealbe.reminderItem"  v-if="item.statisticsCount>0">
                        <i class="circle"></i>有 <span class="number-color">{{item.statisticsCount}}</span>
                        <span v-if="item.reminderType==3||item.reminderType=='3'"> 个候选人推荐接受后</span>
                        <span v-if="item.reminderType==2||item.reminderType=='2'"> 个候选人推荐后</span>
                        <span v-if="item.reminderType==1||item.reminderType=='1'"> 个候选人收到后</span>
                        <span class="date-color">{{item.message}}</span>
                        <span v-if="item.reminderType==3||item.reminderType=='3'">状态未变更</span>
                        <span v-if="item.reminderType==2||item.reminderType=='2'">仍未被处理</span>
                        <span v-if="item.reminderType==1||item.reminderType=='1'">仍未处理</span>
                    </li>
                </ul>
            </div>
            <span slot="footer" class="dialog-footer">
                <button class="content-btn" @click="handleClose">~知道啦~</button>
            </span>
        </el-dialog>
</template>
<script>
import server from '#/js/service/floatingListService.js'
import layerMixin from '#/js/mixins/layerMixin.js'
export default {
    components:{
    },
    mixins: [layerMixin],
    computed:{
        currentDate(){
            let d = new Date();
            let date = d.getDate(),
            year = d.getFullYear(),
            month = d.getMonth()+1;
            return year+"."+month+"."+date
        },
        currentTime(){
            let d = new Date();
            let hours = d.getHours(),
            minutes = d.getMinutes();
            if(hours <10 ){
                hours = "0"+hours;
            }
            if(minutes <10 ){
                minutes = "0"+minutes;
            }
            return hours+":"+minutes;
        }
    },
    data(){
        return{
            dialogVisible: false,
            modalTitle:'Floating List 待处理事项提示小助手',
            untreated:[],
            untreatedCount:0,
            accepted:[],
            acceptedCount:0,
            removealbe:[],
            removealbeCount:0,
        }
    },
    mounted(){
        this.handleShow();
    },
    methods:{
        initEvent(){
            server.getTodoListStatistic().then((res)=>{
                let totalCount = 0;
                res.forEach(item => {
                    item.reminderItem.forEach(data=>{
                        switch(item.reminderType){
                            case 1:
                                this.untreated = item;
                                this.untreatedCount += data.statisticsCount;
                                break;
                            case 3:
                                this.accepted = item;
                                this.acceptedCount += data.statisticsCount;
                                break;
                            case 4:
                                this.removealbe = item;
                                this.removealbeCount += data.statisticsCount;
                                break;
                        }
                        totalCount +=data.statisticsCount;
                    })
                });
                if(totalCount>0){
                    this.layerShow(() => {
                        this.dialogVisible = true;
                    })
                }
            })
        },
        handleShow(){
            if(localStorage.getItem('floatingListTodoModal')){
                let storage = localStorage.getItem('floatingListTodoModal')
                if(storage == this.currentDate){
                    return ;
                }else{
                    localStorage.setItem('floatingListTodoModal',this.currentDate);
                    this.initEvent();
                }
            }else{
                this.initEvent();
                localStorage.setItem('floatingListTodoModal',this.currentDate);
            }
        },
        handleClose(){
            this.layerHide();
            this.dialogVisible = false;
        },
    }
}
</script>
<style lang="scss">
    .floating-list-todo-modal {
        letter-spacing: 0px;
        font-size: 14px;

        .el-dialog {
            width: 608px;
        }
        
        .content-date{
            text-align: center;
            height: 20px;
            .date{
                color: #666666;
                line-height: 19px;
                background: #EEEEEE;
                border-radius: 10px;
                width: 110px;
                display: inline-block;
            }
        }
        .content-list{
            padding-top: 17px;
            margin-bottom: 19px;
            border-top: 1px dashed #DDDDDD;
            color: #444444;
            line-height: 19px;
            &:nth-of-type(2){
                border: none;
                padding-top: 20px;
            }
            .number-color{
                color: #FC7859;
            }
            .date-color{
                color: #38BC9D;
            }
            .list-item{
                margin-top: 6px;
                .circle{
                    width: 6px;
                    height: 6px;
                    border-radius: 6px;
                    background-color: #38BC9D;
                    display: inline-block;
                    margin: 0 6px 1.6px 0;
                }
            }
            .list-title{
                margin-bottom: 4px;
            }
        }
        .content-btn{
            width: 100%;
            height: 36px;
            background-color: #fff;
            border: 1px solid #38BC9D;
            border-radius: 2px;
            color: #38BC9D;
            cursor: pointer;
            &:hover{
                color: #fff;
                background: #38BC9D;
            }
        }
    }
</style>
